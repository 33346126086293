.ca-cart-review-item {
  border-bottom: 1px solid #eeeeee;
}

.ca-cart-review-photo-container {
  max-width: 250px;
  margin-right: 15px;
  padding: 0;
}

.ca-cart-review-photo {
  width: 250px;
}

@media (max-width: 575.98px) {
  .ca-cart-review-photo-container {
      max-width: 100%;
  }

  .ca-cart-review-photo {
      width: 100%;
  }    
}

.ca-cart-item-review-details-container {
  padding-left: 0;
  min-width: calc(100% - 265px);
}

.ca-cart-item-review-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.ca-cart-item-review-date {
  color: #999999;
  margin-bottom: 15px;
}

.ca-cart-item-review-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 400;
}

.ca-cart-item-review-package {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .ca-cart-item-review-title {
      font-size: 16px;
      line-height: 18px;
  }
  
  .ca-cart-item-review-date {
      margin-bottom: 13px;
  }
  
  .ca-cart-item-review-details {
      font-size: 13px;
  }
  
  .ca-cart-item-review-package {
      font-size: 15px;
  }
}

.ca-cart-review-quantity {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 15px;
}

.ca-cart-item-review-price {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 700;
}

.cart-review-subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.cart-review-price {
  font-size: 20px;
  font-weight: 700;
}

.cart-review-price-details {
  font-size: 15px;
  color: #999999;
  margin-bottom: 30px;
  text-align: justify;
}

.cart-review-address-title {
  font-size: 16px;
  font-weight: 700;
}

.cart-review-muted-text {
  font-size: 15px;
  color: #999999;
  text-align: justify;
}