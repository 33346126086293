.security-page-title {
    font-weight: bolder;
    font-size: x-large;
    padding-bottom: 1.5rem;
}

.security-page-form {
    margin-top: 1.5rem;
}


.security-page-form .form-control {
    margin-bottom: 1.5rem;
    width: 30rem;
}

.security-page-form-label {
    font-weight: bolder;
    padding-left: 0.5rem;
}