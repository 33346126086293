/* .project-create-modal .btn {
    border-radius: 4mm;
    font-weight: bolder;
    font-size: large;
    background-color: black;
    color: white;
    border-color: black;
}

.project-create-modal .btn:hover {
    background-color: black;
    border-color: black;
} */

.project-create-modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 17.5rem;
}

.project-create-modal .modal-title {
    font-weight: bolder;
    font-size: x-large;
}

.project-create-modal p {
    font-size: large;
}

.project-create-modal-arrange-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}