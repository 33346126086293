.membership-benefits {
  list-style: none;
  counter-reset: li;
  padding-inline-start: 10px;
  padding-right: 10px;
  /* min-height: 550px; */
}

.membership-benefits>li::before {
  content: counter(li);
  background-color: black;
  color: white;
  display: inline-block;
  width: 1.5em;
  text-align: center;
  margin-left: -1em;
  border-radius: 10rem;
  margin: 5px;  
}

.membership-benefits.current>li::before {
  background-color: #ffffff;
  color: #000000;
}

.membership-benefits>li {
  counter-increment: li;
}

.membership-benefits.title-only>li {
  margin-bottom: 20px;
}