.display-in-portofolio-modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 17.5rem;
}

.display-in-portofolio-modal .modal-title {
    font-weight: 700;
    font-size: 1.35rem;
    text-align: center;
}

.display-in-portofolio-modal p {
    font-size: large;
}

.display-in-portofolio-modal-arrange-text {
    display: flex;
    flex-basis: 75%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}