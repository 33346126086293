.ca-project-photo-container {
    max-width: 250px;
    margin-right: 15px;
    padding: 0;
}

.ca-project-photo {
    width: 250px;
    border-radius: 10px;
}

@media (max-width: 575.98px) {
    .ca-project-photo-container {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .ca-project-photo {
        width: 100%;
    }    
}

.ca-project-title-container {
    padding-left: 0;
    min-width: calc(100% - 265px);
}

.ca-project-title-name {
    font-size: 24px;
    font-weight: 700;
}

.ca-project-title-category {
    font-size: 18px;
    font-weight: 400;
    color: #999999;
}

.ca-project-title-description {
    font-size: 15px;
    font-weight: 400;
    /* color: #999999; */
}

.ca-project-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}