.ca-artwork-column-left {
  padding-left: 0px;
}

.ca-artwork-column-right {
  padding-right: 0px;
}

@media (max-width: 991.98px){
  .ca-artwork-column-left {
      padding-right: 0px;
  } 
  
  .ca-artwork-column-right {
      padding-left: 0px;
  }    
}