.ca-table-billing .ReactTable .rt-thead {
  min-width: 768px !important;
}

.ca-table-billing .ReactTable .rt-tbody {
  min-width: 768px !important;
}

.ca-table-billing .ReactTable .rt-tr .rt-th:first-child {
  width: 15% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:first-child {
  width: 15% !important;
}


.ca-table-billing .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 10% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 10% !important;
}


.ca-table-billing .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-billing .ReactTable .rt-tr .rt-th:nth-of-type(4) {
  width: 50% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:nth-of-type(4) {
  width: 50% !important;
}

.ca-table-billing .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-start !important;
  white-space: normal;
}