.ca-navbar {
    background-color: black;
    height: 4.5rem;
    min-width: 100%;
    display: flex;
    flex-direction: row;
}

#ca-navbar .nav-link { 
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
    text-transform: uppercase;
}

#ca-navbar .nav-link, #ca-navbar .dropdown-menu {
    color: rgba(255,255,255);
    /* font-weight: ; */
    font-size: 1rem;
    letter-spacing: 0.02rem;
    margin-left: 12px;
}

#ca-navbar .dropdown-menu {
    margin-top: 16px;
    background-color: black;
    border-radius: 10px;
}

#ca-navbar .dropdown-item {
    color: white;
    font-size: 15px;
    font-weight: 400;
    padding-left: 10px;
    min-width: 200px;
}

#ca-navbar .nav-link::after {
    display: none;
}

.navbar-brand {
    margin-left: 1rem;
}

.ca-upgrade-button {
    margin-left: 20px;
}

.ca-upgrade-menuitem {
    display: none;
}

.ca-cart {
    margin: 0px 20px 0px 20px !important;
    color: #ffffff;
}

.ca-cart a {
    color: #ffffff !important;
}

.ca-nav-right {
    align-items: center;
}

/* @media (min-width: 1550px) {
    .navbar-custom-nav .nav-link, .navbar-custom-nav .dropdown-menu{
        margin-left: 2.5vw; 
    }
} */

.search-input {
    max-height: 2.5rem;
    max-width: 12.5vw;
    /* margin-left: 10vw; */
    margin-left: 7vw;
    background-color: black;
    font-size: 1rem;
    border: 2px solid white;
    font-family: Roboto, 'Open Sans', 'FontAwesome', sans-serif;
    padding-bottom: 0.55rem;
    border-radius: 5rem;
}

.search-input:focus {
    color: white;
    background-color: black;
}

.search-input::-webkit-input-placeholder {
    color: white;
}

/* .upgrade-button {
    border-radius: 4mm;
    font-weight: bolder;
    font-size: 1vw;
    margin-left: 3.5vw
    margin-left: 1.5vw
} */

.navbar-dropdown-button-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
}

.navbar-user {
    letter-spacing: normal;
    padding-left: 1.5rem;
    font-size: 1.1rem;
}

#ca-navbar .navbar-user-and-icon .nav-link {
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;
}

#ca-navbar .navbar-user-and-icon .dropdown-menu {
    margin-top: 18px;
}

.profile-icon {    
    margin-left: 8px;
    margin-right: 2px;
    background-color: none;
    /* border: 1px solid white; */
    /* border-radius: 50%; */
    /* display: flex; */
    /* align-items: center; */    
}

.navbar-collapse.collapse.show {
    background-color: black;
}

#nav-left {
    order: 0;
}

#nav-middle {
    order: 1;
}

#upgrade-button {
    order: 2;
    margin-left: 20px;
    justify-content: flex-end;
}

#nav-right {
    order: 3;
}

#ca-responsive-navbar {
    display: none;
}

.navbar-custom-nav .dropdown-item, .navbar-user-and-icon .dropdown-item {
    background-color: black;
}

.custom-nav-dropdown.dropdown.nav-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

#ca-navbar .nav-link {
    display: flex;
    align-items: center;
}

.ca-nav-username {
    display: none;
}

.ca-navbar-brand a {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    color: #000000;
}

@media (min-width: 992px) {

    .navbar-expand-xl .navbar-nav {
        width: 100%;
        justify-content: space-around;
    }

    #nav-right {
        order: 2;
        display: flex;
        justify-content: flex-end;
    }
    
    #ca-navbar .navbar-user-and-icon .nav-link {
        margin: 0;
        padding: 0;
    }

    .navbar-user {
        padding: 0;
    }

    #ca-navbar .languages .nav-link{
        padding: 0;
        margin: 0;
    }

    #nav-middle {
        margin: 0;
    }

    #ca-navbar .nav-link .navbar-custom-link {
        margin: 0;
    }

}

/* @media (max-width: 1080px) {
    .navbar-expand-lg {
        flex-flow: row nowrap !important;
        justify-content: flex-start !important;
    }
} */

@media (max-width: 991.98px) {
    #ca-navbar .hidden-sm {
        display: none !important;
    }

    .ca-navbar {
        justify-content: space-evenly;
    }

    .ca-navbar-brand {
        width: calc(100% - 160px);
        text-align: center;
    }

    .navbar-brand {
        margin-right: 0;
        margin-left: 0;
    }

    .navbar {
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
    }

    .navbar-custom-nav {
        padding: 12px;
    }

    #nav-middle {
        order: 0;
        margin-left: 0;
        min-width: 30vw;
    }

    #upgrade-button {
        order: 1;
        margin-left: 0;
        z-index: 10;
    }

    #upgrade-button.collapsing {
        display: none;
    }

    /* .upgrade-button-collapsed {
        margin-left: 1.5vw;
        margin-top: 0.6rem;
    } */
    
    #nav-left {
        order: 2;
        z-index: 10;
    }

    #nav-left.collapsing {
        display: none;
    }
    
    #nav-right {
        order: 2;
        padding-top: 0.25rem;
        padding-bottom: 0.35rem;
        z-index: 10;
    }

    #nav-right .navbar-user-and-icon .dropdown-toggle {
        margin-left: 0;
        padding-left: 0;
        /* padding-top: 0.6rem; */
    }

    #nav-right.collapsing {
        display: none;
    }

    #nav-right .upgrade-button {
        margin-left: 0;
        font-size: medium;
        border-radius: 5mm;
        margin-left: 6vw;
    }

    #nav-right .navbar-user {
        padding-left: 0;
        font-size: 15px;
        padding-top: 0.5vh;
    }

    #nav-right .big-icon {
        font-size: 15px;
    }

    #ca-navbar .navbar-user-and-icon .dropdown-menu {
        margin-top: 0px;
    }

    #ca-navbar .navbar-user-and-icon .dropdown-menu {
        width: 100%;
        border: none;
    }

    .navbar-nav {
        padding-top: 0.3rem;
    }

    #ca-navbar .dropdown .show {
        padding-left: 15px;
    }

    #ca-responsive-navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;;
        width:100%;
        padding-left: 4vw;
        padding-right: 4vw;
    }

    #ca-navbar .nav-link, #ca-navbar .dropdown-menu {
        color: rgba(255,255,255);
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.15rem;
        margin-left: 0px;
    }

    #ca-navbar .dropdown-menu {
        background-color: #ffffff;
        border-radius: 4px;
        margin-top: 5px;
        width: 100%;
        padding: 0;
        /* margin: 0; */    
    }

    .navbar-custom-nav .dropdown-item {
        color: #000000 !important;
        background-color: #ffffff;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    #ca-navbar .nav-link { 
        padding-bottom: 0rem;
        padding-top: 0.6rem;
    }

    #ca-navbar .custom-nav-dropdown.administration {
        margin-bottom: 0.2rem;
    }
    
    #ca-navbar .custom-nav-dropdown.administration .nav-link, #ca-navbar .custom-nav-dropdown.artworks .nav-link {
        padding-bottom: 0;
    }

    #ca-navbar div.dropdown-menu::show {
        margin: 0;
    }

    #ca-navbar .dropdown-item {
        /* color: #000000; */
        font-size: 15px;
        font-weight: 400;
        padding-left: 0px;
    }

    .ca-nav-right {        
        flex-direction: row;
    }

    .ca-nav-right .dropdown-item {
        color: #ffffff;
        text-align: right;
        padding-right: 12px;
    }

    .ca-nav-right .dropdown .show {
        padding-left: 15px !important;
        background-color: #000000 !important;
        border-radius: 0px !important;
    }

    .ca-nav-right .dropdown-menu.show {
        position: absolute;
        min-width: 100vw;
        left: calc(-100vw + 62px) !important;
        top: 57px !important;
        right: 0;
    }

    .ca-cart {
        margin: 0 5px 0 0 !important;
    }
    
    .ca-nav-username {
        display: inline-block;
        text-align: right;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        padding-right: 12px;
        padding-bottom: 5px;
    }

    .ca-upgrade-menuitem {
        width: 100%;
        text-align: right;
        display: inline-block;
        color: #000000 !important;
        margin-bottom: 5px;        
    } 

    .ca-upgrade-menuitem-container {
        background-color: #ffffff !important;
        margin-right: 12px !important;
        padding: 4px 2px;
        border-radius: 4px;
    }

    .ca-upgrade-menuitem a {
        color: #000000 !important;
    }
    
    .ca-navbar .navbar-toggler {
        border: 0px;
    }

    .ca-navbar .navbar-toggler-icon {
        background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }    
}