.create-project-card.card {
    border-radius: 20px;
    height: 300px;
    margin-right: 30px;
    color: #ffffff;
    background-color: #000000;
    margin-bottom: 15px;
    width: 100%;
    border: 0px;
    text-align: center;
    align-items: center;
}

@media (max-width: 991.98px){
    .create-project-card.card {
        margin-right: 0;
    }    
}

.create-project-card.card:hover {
    cursor: pointer;
}

.create-project-card.card .card-body {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 10px;
}

.create-project-card.card .card-title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
}

.create-project-card.card .card-title:hover {
    color: #ffffff !important;
}

.create-project-card.card .card-text {
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
}

@media (min-width: 992px) and (max-width: 1199.98px){
    .create-project-card.card .card-title {
        font-size: 22px;
        line-height: 24px;
    }    
    .create-project-card.card .card-text {
        font-size: 18px;
        line-height: 20px;
    }    
}

.create-project-card a {
    all: unset;
    display: flex;
    flex: 1 0;
}

.create-project-card.disabled {
    /* background-color: rgba(150, 150, 150, 1); */
    background-color: rgba(0, 0, 0, 1);
}

.create-project-card.disabled:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
}

.create-project-card.disabled:hover {
    cursor: default;
}

.create-project-card img {
    padding-top: 15px;
    width: 100px !important;    
}