* {
    text-decoration: none !important;
}

.ReactTable .rt-noData {
    margin-top: 1.5rem;
    background: none !important;
}

.stepper-button {
    border-radius: 3mm;
    min-height: 2.2rem;
    height: 2.2rem;
    font-weight: bold;
    font-size: 1rem;
    background-color: black;
    color: white !important;
    border-color: black;
    padding:0;
}

.back-next {
    min-width: 5rem;
}

.save-changes {
    min-width: 9rem;;
}
    
.stepper-button:hover {
    background-color: black;
    border-color: black;
}

.photo-publish-col-direction-column {
    display: flex;
    flex-direction: column;
}

.photo-publish-page-title {
    font-size: x-large;
    font-weight: 900;
}

.photo-publish-page-sub-title {
    font-weight: bold;
    font-size: medium;
    text-decoration: underline white;
    margin-top: -0.2rem;
    color: darkgrey;
}

.photo-publish-page-section-title {
    font-size: large;
    font-weight: bold;
}

.photo-publish-form {
    margin-top: 0.5rem;
}

.photo-publish-form .form-label {
    font-weight: bold;
    margin-left: 0.5rem;
}

.photo-publish-form .form-control {
    width: 15rem;
    text-decoration: underline white;
}

.photo-first-step-column {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ca-publish-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ca-publish-section-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 2px;
}

.ca-publish-section-subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #999999;
    margin-bottom: 10px;
}

.photo-first-step-artwork-details-form {
    margin-top: 1rem;
}

.photo-first-step-artwork-details-form .form-control {
    min-width: 20rem;
    text-decoration: underline white;
}

.photo-second-step-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.photo-second-step-column-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.photo-second-step-dropzone-title {
    font-weight: 800;
    font-size: x-large;
    text-decoration: underline white;
}

.photo-second-step-dropzone-sub-title {
    font-weight: bold;
    font-size: medium;
    text-decoration: underline white;
    margin-top: -0.6rem;
    color: darkgrey;
}
