.login-page-align {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.login-page-card {
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
}

.login-page-card-submit-button-section {
    text-align: center;
}

.login-page-card-title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.login-page-logo {
    height: 8rem;
    margin-bottom: 4rem;
}

.ca-link-white {
  color:white !important;
  margin-left:5px;
  margin-right:5px;
}