.ca-artwork-publish-modal .modal-body .modal-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.ca-artwork-publish-modal .modal-body .description {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
}

.ca-artwork-publish-modal .modal-footer .second-col {
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
}

.ca-artwork-publish-modal a {
    text-decoration: underline !important;
    font-weight: 700;
    font-size: 13px;
}