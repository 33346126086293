@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap);
html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  color: #000000;
  font-family: Roboto, 'Open Sans', 'FontAwesome', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
}

body a,
body a:visited,
body a:focus {
  color: #000000;
}

#main-container {
  flex: 1 1 auto;
  padding: 0 !important;
  overflow-y: auto;
  height: 100px;
  /* flex will automatically grow to fill available height */
}

.main-container .row {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1.5rem;
}

.main-container .col:nth-child(0) {
  padding-left: 0;
}

.main-container .col:nth-last-child() {
  padding-right: 0;
}

code {
  font-family: Roboto, 'Open Sans', 'FontAwesome', sans-serif
}

.ca-hr {
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  border: 0;
  border-top: 2px solid #000000 !important;
}

.ca-hr.muted {
  border-top: 1px solid #cccccc !important;
}

.ca-single-button-row {
  display: flex;
  justify-content: flex-end;
}

.ca-double-button-row {
  display: flex;
  justify-content: flex-end;
}

.ca-profile-photo {
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
}

.ca-profile-pic-container {
  max-width: 150px;
  margin-right: 15px;
  padding: 0;
  position: relative;
}

@media (max-width: 575.98px) {
  .ca-profile-pic-container {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
  }
}

.profile-pic {
  height: 150px;
  width: 150px;
}

.profile-pic.small {
  height: 75px;
  width: 75px;
}

.profile-pic-list {
  height: 100px;
  width: 100px;
  min-width: 100px;
}

.ca-profile-details-container {
  min-width: calc(100% - 165px);
}

.ca-profile-pic-container:hover img {
  opacity: 0.3;
}

.ca-profile-pic-container:hover.view img {
  opacity: 1;
}

.ca-profile-photo-change {
  transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  display: none;
  font-size: 14px;
  font-weight: 700;
}

.ca-profile-pic-container:hover .ca-profile-photo-change {
  display: block;
  opacity: 1;
}

.ca-bkg-white {
  background-color: #ffffff;
}

.ca-color-white {
  color: #ffffff !important;
}

/* .ca-profile-artwork-item {
  height: 350px; 
  background-position: center; 
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1499.98px) {
  .ca-profile-artwork-item {
    height: 300px;
  }
}
@media (max-width: 1199.98px) {
  .ca-profile-artwork-item {
    height: 250px;
  }
}
@media (max-width: 991.98px) {
  .ca-profile-artwork-item {
    height: 250px;
  }
} */

.ca-profile-artwork-item-link {
  cursor: pointer;
}

.ca-profile-artwork-item {
  margin-right: 30px;
  margin-bottom: 20px;
}

.ca-profile-artwork-item-name {
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding: 20px 5px;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 300px;
}

.ca-profile-artwork-img {
  width: 300px;
  flex-direction: column;
}

.ca-profile-artwork-img img {
  width: 300px;
  height: 350px;
  object-fit: cover;
  object-position: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

@media (max-width: 679.98px) {
  .ca-profile-artwork-item-link {
    width: 100% !important;
  }

  .ca-profile-artwork-item {
    width: 100% !important;
    justify-content: center;
  }
}


@media (max-width: 991.98px) {
  .ca-double-button-row {
    justify-content: flex-end;
  }
}

.ca-double-button-row-flex-start {
  display: flex;
  justify-content: flex-start;
}


@media (max-width: 992px) {
  .ca-double-button-row-flex-start {
    display: flex;
    justify-content: space-between;
  }
}

.ca-go-left-row {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 992px) {
  .ca-go-left-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .ca-page-padding {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .ca-page-title-padding {
    padding: 0px 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ca-page-padding {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .ca-page-title-padding {
    padding: 0px 25px !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ca-page-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .ca-page-title-padding {
    padding: 0px 10px !important;
  }
}

@media (max-width: 575.98px) {
  .ca-page-padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .ca-page-title-padding {
    padding: 0px 5px !important;
  }
}

.ca-main-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.Toastify__toast--success {
  background-color: #00695C !important;
}

.Toastify__toast {
  border-radius: 10px !important;
}

.ca-footer {
  align-items: center;
  margin: 0;
  padding: 10px 10px;
  background-color: #000000;
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
}

.ca-footer a,
.ca-footer a:active,
.ca-footer a:visited,
.ca-footer a:focus {
  color: #ffffff;
}

.ca-section-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  /* text-transform: uppercase; */
}

.ca-artwork-name {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 5px;
}

.ca-art-date,
.ca-art-type {
  font-size: 16px;
  color: #999999;
}

.ca-art-package {
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
}

/* .ca-artwork-image {
} */
@media (max-width: 991.98px) {
  .ca-artwork-image {
    justify-content: center;
    align-items: center;
  }
}

.ca-artwork-image .image-gallery .image-gallery-left-nav:active::before,
.ca-artwork-image .image-gallery .image-gallery-left-nav:hover::before,
.ca-artwork-image .image-gallery .image-gallery-right-nav:active::before,
.ca-artwork-image .image-gallery .image-gallery-right-nav:hover::before {
  color: #ffffff !important;
}

.card.bg-dark {
  background-color: #000000 !important;
}

.card.bg-light {
  background-color: #ffffff !important;
}

.card.bg-disabled {
  background-color: #cccccc !important;
}

.card.text-disabled {
  color: #777777 !important;
}

.ca-artwork-pricing-card {
  border-radius: 10px;
  margin-bottom: 15px;
}

.ca-artwork-pricing-card.black-card {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.ca-soldout-image {
  width: 80%;
  position: absolute;
  top: 25%;
  left: 10%;
}

.ca-soldout-image.small {
  width: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

@media (max-width: 1199.98px) {
  .ca-soldout-image.small {
    width: 60%;
    top: 30%;
    left: 20%;
  }
}

@media (max-width: 991.98px) {
  .ca-soldout-image.small {
    width: 80%;
    top: 35%;
    left: 10%;
  }
}

@media (max-width: 575.98px) {
  .ca-soldout-image.small {
    width: 80%;
    top: 25%;
    left: 10%;
  }
}

.ca-limited-image {
  width: 60px;
  position: absolute;
  top: 60px;
  right: 10px;
}

.ca-limited-image.ca-limited-modal-image {
  width: 80px;
  right: 20px;
}

.ca-artwork-pricing-card .ca-card-detail-text {
  font-size: 15px;
  color: #999999;
}

.ca-artwork-pricing-card .card-header {
  border: 0px solid #ffffff;
  background-color: transparent;
  padding-bottom: 0;
}

.ca-artwork-pricing-card .card-header .card-title {
  margin-bottom: 0;
}

.ca-artwork-pricing-card .card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0px solid #ffffff;
  background-color: transparent;
  padding-top: 0;
}

.ca-artwork-pricing-card.details-card .card-footer {
  align-items: flex-start;
  min-height: 75px;
}

.ca-artwork-pricing-card .more-info-button {
  cursor: pointer;
  color: #999999;
  text-align: right;
}

.ca-artwork-pricing-card .card-header,
.ca-artwork-pricing-card .card-body,
.ca-artwork-pricing-card .card-footer {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1199.98px) {
  .ca-artwork-pricing-card.details-card .card-footer {
    min-height: 90px;
  }
}

@media (max-width: 991.98px) {
  .ca-artwork-pricing-card.details-card .card-footer {
    min-height: 50px;
  }
}

@media (max-width: 767.98px) {

  .ca-artwork-pricing-card .card-header,
  .ca-artwork-pricing-card .card-body,
  .ca-artwork-pricing-card .card-footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ca-artwork-pricing-card.details-card .card-footer {
    min-height: 70px;
  }
}

@media (max-width: 575.98px) {
  .ca-artwork-pricing-card.details-card .card-footer {
    min-height: auto;
  }
}

.ca-paragraph-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.ca-paragraph-subtitle {
  font-size: 18px;
  font-weight: 700;
}

/* Modal */
/* overide */
.modal-dialog {
  display: flex !important;
  justify-content: center !important;
}

.modal-content {
  /* width: 30rem;   */
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.2);
}

.modal-header {
  border: 0px solid #999999;
  padding-bottom: 0;
}

.modal-header .close {
  line-height: 36px;
  text-shadow: none;
  opacity: 1;
}

.modal-body {
  padding-bottom: 0;
}

.modal-footer {
  border: 0px solid #999999;
  justify-content: flex-start;
}

.modal-header .modal-title {
  font-weight: 700;
  margin-bottom: 0;
}

.ca-modal .empty-modal-header {
  height: 30px;
}

.ca-modal .ca-modal-title {
  background-color: #000000;
  color: #FFFFFF;
  padding: 1rem;
  font-size: 20px;
  font-weight: 700;
}

.ca-modal-delete-confirm {
  font-size: 18px;
}

.ca-modal-confirm-title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.ca-modal-confirm-subtitle {
  font-size: 13px;
  color: #999999;
  text-align: center;
}

.ca-modal-close button:active,
.ca-modal-close button:hover,
.ca-modal-close button:focus {
  outline: none;
}

.ca-modal-close.dark {
  background-color: #000000;
  color: #FFFFFF;
  padding-bottom: 1rem;
}

.ca-modal-close.dark button {
  color: #FFFFFF;
}

.ca-modal .modal-body {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

/* Terms */
.ca-terms h2 {
  font-size: 20px;
  font-weight: 700;
}

.ca-terms-paragraph a {
  text-decoration: underline !important;
}

.ca-terms-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.ca-terms-ol {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
  font-size: 20px;
  color: #000000;
  font-weight: 400;
}

.ca-terms-ol ul {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.ca-page-title {
  font-weight: 400;
  font-size: 28px;
}

@media (max-width: 575.98px) {
  .ca-page-title {
    font-size: 24px;
    line-height: 26px;
  }

  .ca-page-title-container {
    margin-bottom: 10px;
  }
}

.ca-publish-page-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  background-color: #000000;
  color: #FFFFFF;
  margin: 10px 0px 20px 0px;
  height: 60px;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.ca-publish-page-title .icon {
  height: 56px;
  margin-right: 20px;
}

.ca-publish-page-title .icon-right {
  height: 56px;
  margin-left: 20px;
}

@media (max-width: 575.98px) {
  .ca-publish-page-title .icon {
    margin-right: 10px;
  }

  .ca-publish-page-title .icon .icon-right {
    margin-left: 10px;
  }
}

.ca-publish-page-footer {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  background-color: #000000;
  color: #FFFFFF;
  margin: 0px 0px 10px 0px;
  height: 60px;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ca-publish-page-title {
    font-size: 20px;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ca-publish-page-title {
    font-size: 20px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 10px;
  }

  .ca-publish-page-footer {
    margin: 0px 0px 10px 0px;
  }
}

@media (max-width: 575.98px) {
  .ca-publish-page-title {
    font-size: 16px;
    line-height: 20px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-top: 10px;
  }

  .ca-publish-page-title button,
  .ca-publish-page-title button img {
    height: 40px;
  }

  .ca-publish-page-title .publish-buttons {
    width: 100%;
    justify-content: flex-end;
  }

  .ca-publish-page-footer {
    font-size: 16px;
    margin: 0px 0px 10px 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.ca-page-subtitle {
  font-weight: 400;
  font-size: 20px;
}

.ca-page-title-button-row,
.ca-page-title-search-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 767.98px) {
  .ca-page-title-button-row {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .ca-page-title-button-row-buttons {
    width: 100%;
    text-align: right;
  }
}

@media (max-width: 575.98px) {
  .ca-page-title-search-row-search {
    width: 100%;
  }
}

.ca-artwork-card {
  display: inline-block;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  border: 0px;
  margin-right: 0px;
  margin-top: 0.75rem;
  width: 100%;
}

.ca-artwork-card .card-body {
  padding: 10px 10px 10px 10px
}

.ca-artwork-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ca-artwork-card.card .card-body .card-title {
  font-size: 18px;
  line-height: 22px;
}

.ca-artwork-card.card .card-body .card-text {
  margin-bottom: 2px;
}

.ca-artwork-card.card .card-footer {
  border: 0px solid #ffffff;
  background-color: transparent;
  padding: 10px 10px 10px 10px
}

.ca-artwork-card.card .card-footer .form-check-input {
  margin-top: 0;
  width: 16px;
  height: 16px;
  background-color: #000000 !important;
}

.ca-artwork-card.card .card-footer .form-check-label {
  color: #999999;
  padding-top: 2px;
}

.ca-artwork-card.card .card-footer .ca-footer-link {
  cursor: pointer;
  font-size: 14px;
}

.ca-artwork-card.card .card-footer .ca-footer-controls {
  text-align: right;
  font-size: 20px;
  /* padding-top: 10px; */
}

.ca-card {
  display: inline-block;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  border: 0px;
  margin-right: 0px;
  margin-bottom: 0.75rem;
  width: 100%;
}

.ca-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ca-card .card-body {
  height: 175px;
  padding: 10px;
}

.ca-add-on-card .card-body {
  height: 175px;
  padding: 10px;
}

@media (max-width: 1199.98px) {
  .ca-card .card-body {
    height: 200px;
  }

  .ca-add-on-card .card-body {
    height: 200px;
  }
}

@media (max-width: 767.98px) {
  .ca-card .card-body {
    height: 175px;
  }

  .ca-add-on-card .card-body {
    height: 175px;
  }
}

@media (max-width: 575.98px) {
  .ca-card .card-body {
    height: auto;
  }

  .ca-add-on-card .card-body {
    height: auto;
  }
}

.ca-card .card-body .card-title {
  font-size: 18px;
  line-height: 22px;
}

.ca-card .card-body .card-subtitle {
  color: #999999;
  margin-bottom: 10px;
}

.ca-card .card-body .card-text {
  margin-bottom: 2px;
}

.ca-card .card-footer {
  padding: 0px 10px 10px 10px;
  border: 0px solid #ffffff;
  background-color: transparent;
  min-height: 35px;
  line-height: 16px;
}

.ca-add-on-collection-card {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  border: 0px;
  margin-right: 0px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.ca-add-on-collection-card {
  background-color: #555555;
  border-radius: 10px;
  min-height: 200px;
  opacity: 0.8;
}

.ca-add-on-collection-card .card-header {
  border: 0;
  background-color: transparent;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
}

.ca-add-on-collection-card .card-body {
  padding-top: 0;
}

.ca-add-on-collection-card .card-body .card-title,
.ca-add-on-collection-card .card-body .card-title:hover {
  color: #ffffff !important;
  font-size: 18px;
  line-height: 22px;
}

.ca-add-on-collection-card .card-body .card-subtitle {
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 400;
}

.ca-add-on-collection-card .card-body .card-text {
  color: #ffffff;
  margin-bottom: 2px;
}

.card-columns {
  width: 100%;
  -webkit-column-count: 4;
          column-count: 4;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card-columns {
    -webkit-column-count: 2;
            column-count: 2;
  }
}

@media (max-width: 575.98px) {
  .card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}

.ca-columns-3.card-columns {
  width: 100%;
  -webkit-column-count: 3;
          column-count: 3;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .ca-columns-3.card-columns {
    -webkit-column-count: 3;
            column-count: 3;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ca-columns-3.card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}

@media (max-width: 575.98px) {
  .ca-columns-3.card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}

.ca-image-columns-6 {
  width: 100%;
  -webkit-column-count: 6;
          column-count: 6;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .ca-image-columns-6 {
    -webkit-column-count: 6;
            column-count: 6;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .ca-image-columns-6 {
    -webkit-column-count: 4;
            column-count: 4;
  }
}

@media (max-width: 575.98px) {
  .ca-image-columns-6 {
    -webkit-column-count: 2;
            column-count: 2;
  }
}

.ca-image-columns .img-container {
  margin-bottom: 5px;
}

.ca-image-columns .img-container img {
  width: 100%;
}

.ca-gallery-card.card {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .ca-gallery-card.card {
    margin-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ca-gallery-card.card {
    margin-right: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ca-gallery-card.card {
    margin-right: 20px;
  }
}

@media (max-width: 575.98px) {
  .ca-gallery-card.card {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.ca-gallery-card.card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  object-position: center;
  height: 300px;
}

.ca-gallery-card.card .card-body {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  /* height: 85px; */
}

.ca-gallery-card.card .card-title {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  min-height: 40px;
  margin-bottom: 8px;
}

.ca-gallery-card.card .card-footer {
  border: 0px solid #ffffff;
  background-color: transparent;
  padding: 10px 10px 10px 10px
}

.ca-gallery-card.card .card-footer .form-check-input {
  margin-top: 0;
  width: 16px;
  height: 16px;
  background-color: #000000 !important;
}

.ca-gallery-card.card .card-footer .form-check-label {
  color: #999999;
  padding-top: 2px;
}

.ca-gallery-card-footer {
  text-align: right;
  font-size: 20px;
}

.card {
  transition: 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.card .card-body .card-title:hover,
.card .card-body .card-title:active {
  color: #000000 !important;
}

.card.bg-dark .card-body .card-title:hover,
.card.bg-dark .card-body .card-title:active {
  color: #ffffff !important;
}

.card .disabled {
  cursor: auto;
}

.ca-video {
  width: 100% !important;
  height: auto !important;
}

.ca-youtube-video-container {
  position: relative;
  padding-bottom: 48%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.ca-youtube-video-container iframe,
.ca-youtube-video-container object,
.ca-youtube-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ca-novideo-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

@media (max-width: 991.98px) {
  .ca-novideo-container-md-hide {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .ca-novideo-container-xs-hide {
    display: none;
  }
}

.ca-novideo {
  background-color: #000000;
  text-align: center;
  /* min-height: 200px; */
}

.ca-no-video img {
  width: 68pxs;
}

/* Form */
.ca-button {
  color: #ffffff;
  background-color: #000000;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  padding: 7px 15px 5px 15px !important;
  border-radius: 20px !important;
  white-space: nowrap;
  border: 1px solid #000000 !important;
  vertical-align: middle !important;
  height: 100%;
  max-height: 30px !important;
  text-transform: uppercase !important;
}

.ca-button-margin-top {
  margin-top: 2px;
}

.ca-button:active,
.ca-button.active,
.ca-button:hover,
.ca-button:focus,
.ca-button.focus {
  color: #ffffff !important;
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0 0 0 0.1rem rgba(82, 88, 93, 0.5);
}

.ca-button-white {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.ca-button-white a {
  color: #000000;
}

.ca-button-white:active,
.ca-button-white.active,
.ca-button-white:hover,
.ca-button-white:focus,
.ca-button-white.focus {
  color: #000000 !important;
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  box-shadow: 0 0 0 0.1rem rgba(82, 88, 93, 0.5);
}

.ca-button-green {
  color: #ffffff;
  background-color: #2E7D32;
  border: 1px solid #2E7D32 !important;
}

.ca-button-green a {
  color: #ffffff;
}

.ca-button-green:active,
.ca-button-green.active,
.ca-button-green:hover,
.ca-button-green:focus,
.ca-button-green.focus {
  color: #ffffff !important;
  background-color: #2E7D32 !important;
  border: 1px solid #2E7D32 !important;
  box-shadow: 0 0 0 0.1rem rgba(46, 125, 50, 0.5);
}

.ca-button.disabled,
.ca-button:disabled {
  color: #777777;
  background-color: #cccccc;
  border-color: #cccccc;
  pointer-events: none;
}

.ca-button.ca-button-link {
  color: #000000;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding: 0;
  white-space: nowrap;
  border: 0px solid transparent !important;
  text-transform: none;
}

.ca-button.ca-button-link.ca-button:active,
.ca-button.ca-button-link.active,
.ca-button.ca-button-link:hover,
.ca-button.ca-button-link:focus,
.ca-button.ca-button-link.focus {
  color: #000000 !important;
  background-color: transparent !important;
  border: 0px solid transparent !important;
  box-shadow: none;
  outline-color: transparent;
}

.ca-button-icon {
  color: transparent;
  background-color: transparent;
  padding: 0px !important;
  white-space: nowrap;
  border: 0px solid transparent !important;
  vertical-align: middle !important;
  height: 60px;
  max-height: 60px !important;
  outline-color: transparent !important;
}

.ca-button-icon.medium {
  height: 40px;
  max-height: 40px !important;
}

.ca-button-icon.small {
  height: 30px;
  max-height: 30px !important;
}

.ca-button-icon:active,
.ca-button-icon.active,
.ca-button-icon:hover,
.ca-button-icon:focus,
.ca-button-icon.focus {
  color: transparent !important;
  background-color: transparent !important;
  border: 0px solid transparent !important;
  box-shadow: none;
  outline: none;
  outline-color: transparent !important;
}

.ca-button-icon img {
  height: 100%;
  margin-right: 8px;
}

@media (max-width: 575.98px) {
  .ca-button-icon img {
    margin-right: 4px;
    width: 50px;
  }
}

.ca-button-icon.disabled,
.ca-button-icon:disabled {
  pointer-events: none;
  opacity: 0.5 !important;
  background-color: transparent !important;
}

.ca-text-disabled {
  color: #999999;
}

.ca-text-disabled-dark {
  color: #777777;
}

.ca-form {
  font-size: 15px;
}

.ca-form .form-control {
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  max-width: 100%;
  height: calc(1.5em + .75rem + 2px);
  /* form control height standard */
  margin-bottom: 5px;
}

.ca-form textarea.form-control {
  height: auto;
}

.ca-form .ca-dropdown {
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  max-width: 100%;
  height: calc(1.5em + .75rem + 2px);
  /* form control height standard */
  margin-bottom: 5px;
}

.ca-form .form-label,
.ca-form label {
  padding-left: 0;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
}

.ca-label {
  padding-left: 0;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.ca-view-value {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.ca-display-group {
  margin-bottom: 15px;
}

.ca-description-value {
  text-align: justify;
  font-size: 15px;
}

.ca-description-value-scroll {
  text-align: justify;
  font-size: 15px;
  height: 150px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.ca-form .summernote,
.ca-form .dropzone {
  width: 100%;
  max-width: 100%;
}

.ca-text-editor .note-frame,
.ca-text-editor .sun-editor,
.ca-text-editor .sun-editor .se-container {
  border-radius: 10px;
}

.ca-form .ca-col-left-nopadding {
  padding-left: 0px;
  padding-right: 7px;
}

.ca-form .ca-col-right-nopadding {
  padding-left: 7px;
  padding-right: 0px;
}

@media (max-width: 991.98px) {
  .ca-form .ca-col-left-nopadding.col-lg-6 {
    padding-right: 0px;
  }

  .ca-form .ca-col-right-nopadding.col-lg-6 {
    padding-left: 0px;
  }
}

@media (max-width: 767.98px) {
  .ca-form .ca-col-left-nopadding.col-md-6 {
    padding-right: 0px;
  }

  .ca-form .ca-col-right-nopadding.col-md-6 {
    padding-left: 0px;
  }
}

@media (max-width: 575.98px) {
  .ca-form .ca-col-left-nopadding.col-sm-6 {
    padding-right: 0px;
  }

  .ca-form .ca-col-right-nopadding.col-sm-6 {
    padding-left: 0px;
  }
}

.ca-text-editor .se-toolbar {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  outline-color: transparent !important;
  border-bottom: 1px solid rgb(218, 218, 218);
}

.ca-text-editor .note-frame .note-statusbar,
.ca-text-editor .sun-editor .se-resizing-bar {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ca-text-editor {
  margin-bottom: 20px;
}

.stepper-dropzone {
  width: 100% !important;
  max-width: 100%;
  margin-top: 0 !important;
  /* margin-bottom: 20px; */
  z-index: -1;
}

.stepper-dropzone.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 13.5rem;
  background-color: black;
  border-radius: 2mm;
  margin-right: 0;
}

.ca-dropzone-tall .stepper-dropzone.card {
  height: 21.5rem;
}

.stepper-dropzone .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 98%;
  max-width: 98%;
  max-height: 94%;
  color: RGBA(255, 255, 255, 0.7);
  text-align: center;
  background-color: black;
  text-decoration: underline;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  border: 0.05rem dashed white;
}

.stepper-dropzone .card-body .card-text {
  margin-bottom: 0;
  font-size: 13px;
}

.stepper-dropzone .card-body .card-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.stepper-dropzone .card-body .card-title:hover {
  color: RGBA(255, 255, 255, 0.7) !important;
}

.stepper-dropzone .card-body .card-title:nth-last-of-type(1) {
  margin-bottom: 15px;
}

/* overide */
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  border: 1px solid #cccccc;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 2.5rem;
}

.form-control:focus {
  color: #777777;
  background-color: #fff;
  border-color: #cccccc;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(204, 204, 204, 0.25);
}

.ca-form-control-search.form-control::-webkit-input-placeholder {
  text-align: right;
  color: #cccccc;
}

.ca-form-control-search.form-control::-moz-placeholder {
  text-align: right;
  color: #cccccc;
}

.ca-form-control-search.form-control::-ms-input-placeholder {
  text-align: right;
  color: #cccccc;
}

.ca-dropdown .btn {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #cccccc;
  color: #777777;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  font-size: 17px;
}

.ca-dropdown .btn:active,
.ca-dropdown .btn.active .ca-dropdown .btn:focus,
.ca-dropdown .btn.focus {
  color: #777777 !important;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
}

.ca-dropdown .btn:hover {
  color: #777777 !important;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
}

.ca-dropdown.show>.dropdown-toggle {
  color: #777777 !important;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
  box-shadow: none;
}

.ca-dropdown .btn:focus,
.ca-dropdown.show>.dropdown-toggle:focus {
  box-shadow: none !important;
}

.ca-dropdown .dropdown-menu.show {
  width: 100%;
  border-radius: 10px;
  background-color: #000000;
}

.ca-dropdown .dropdown-menu {
  font-size: 14px;
}

.ca-dropdown .dropdown-menu .dropdown-item {
  padding: 4px 12px;
  color: #ffffff;
}

.ca-dropdown .dropdown-menu .dropdown-item:hover,
.ca-dropdown .dropdown-menu .dropdown-item:focus {
  color: #000000;
  text-decoration: none;
  background-color: #ffffff;
}

.ca-dropdown .dropdown-menu .dropdown-item.active,
.ca-dropdown .dropdown-menu .dropdown-item:active {
  color: #000000;
  text-decoration: none;
  background-color: #ffffff;
}

.ca-dropdown .dropdown-menu .dropdown-item.disabled,
.ca-dropdown .dropdown-menu .dropdown-item:disabled {
  color: #777777;
  pointer-events: none;
  background-color: transparent;
}

.ca-terms-link {
  text-decoration: underline !important;
  font-weight: 700;
}

.ca-beta-version {
  color: #ffffff !important;
  position: relative;
  top: -15px;
  right: 15px;
  font-size: 12px;
  font-weight: 700;
}

.ca-beta-version a {
  color: #ffffff !important;
}

.ca-map-column {
  position: "relative";
  height: calc(100vh - 112px);
}

.ca-map-full-page {
  position: "relative";
  height: calc(100vh - 111px);
}

.ca-map-full-page-with-header {
  position: "relative";
  height: calc(100vh - 171px);
}

@media (max-width: 575.98px) {
  .ca-map-full-page {
    height: calc(100vh - 130px);
  }

  .ca-map-full-page-with-header {
    height: calc(100vh - 240px);
  }
}

.ca-map-h100 {
  position: "relative";
  flex: 1 1 auto;
  padding: 0 !important;
  overflow-y: auto;
  height: 100px;
  /* flex will automatically grow to fill available height*/
}

.ca-map-modal {
  width: 90% !important;
  height: 90vh;
  margin: auto;
}

@media (max-width: 575.98px) {
  .ca-map-modal {
    width: 95% !important;
  }
}

.ca-map-modal.modal-dialog {
  max-width: none;
}

.ca-map-modal .modal-content {
  height: 100%;
}

.ca-dropzone-disabled {
  pointer-events: none;
}

.ca-dropzone-disabled .stepper-dropzone {
  opacity: 0.5;
}

.ca-dropzone-enabled {
  pointer-events: auto;
}

.ca-question-icon:before {
  font-family: "FontAwesome";
  margin-left: 5px;
  cursor: pointer;
  content: "\f059";
}

.ca-form-image-container,
.ca-form-object-container {
  text-align: center;
  margin: auto;
  display: inline-block;
  position: relative;
}

.ca-form-image-container.ca-left,
.ca-form-object-container.ca-left {
  text-align: left;
  margin-left: 0px;
}

.ca-form-image-container-hide,
.ca-form-object-container-hide {
  display: none;
}

.ca-form-image-container img {
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 10px;
  margin: auto;
  cursor: pointer;
  max-height: 352px;
  max-width: 100%;
}

.ca-form-image-change-details {
  transition: .5s ease;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  display: none;
  font-size: 14px;
}

.ca-form-object-change-details {
  transition: .5s ease;
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  display: block;
  font-size: 14px;
}

.ca-form-image-container:hover img {
  opacity: 0.3;
}

.ca-form-image-container:hover .ca-form-image-change-details,
.ca-form-object-container:hover .ca-form-object-change-details {
  opacity: 1;
  display: block;
}

.ca-dropzone-show {
  text-align: center;
  margin: auto;
  display: inline-block;
  position: relative;
  max-height: 400px;
  width: auto;
}

.ca-dropzone-show.ca-left {
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
}

.ca-dropzone-show .stepper-dropzone.card {
  height: 352px;
}

.ca-dropzone-show-wide {
  width: 100%;
}

.ca-dropzone-show.fixed-width {
  max-width: 500px;
}

.ca-dropzone-hide {
  display: none !important;
}

.ca-hide-button {
  display: none !important;
}

.ca-form-input-group .input-group-prepend .input-group-text,
.ca-form-input-group .input-group-append .input-group-text {
  border-color: #cccccc;
  border-left: 0px solid #cccccc;
  background-color: transparent;
  color: #cccccc;
}

.ca-form-input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ca-form-input-group .input-group-append {
  width: 40px;
}

.ca-form-input-group .input-group-append .input-group-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ca-form-input-group .form-control {
  width: calc(100% - 40px) !important;
}

.ca-profile-form-date {
  display: flex;
  flex-direction: column;
}

.ca-membership-card.card {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 40px;
  border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .ca-membership-card.card {
    margin-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ca-membership-card.card {
    margin-right: 30px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ca-membership-card.card {
    margin-right: 40px;
  }
}

@media (max-width: 575.98px) {
  .ca-membership-card.card {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.ca-membership-card.card img {
  height: 50px;
  width: auto;
}

.ca-membership-card.card .card-body {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.ca-membership-card.card .card-header {
  text-align: center;
  background-color: transparent;
  border: 0px solid transparent;
}

.ca-membership-card.card .card-title {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .dropdown-divider {
    margin-left: 3px;
    margin-right: 3px;
  }
}

@media (max-width: 991.98px) {
  .dropdown-divider {
    margin-right: 15px;
  }
}

.ca-form-view-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.font-bold {
  font-weight: 700;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.warning-color {
  color: #FFAB00;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.ca-image-card {
  display: inline-block;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  border: 0px;
  margin-right: 0px;
  margin-top: 0.75rem;
  width: 100%;
}

.ca-image-card .card-body {
  padding: 0px;
}

.ca-image-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 250px;
  object-fit: cover;
  object-position: center top;
}

.ca-image-card-footer {
  text-align: right;
  font-size: 20px;
  background-color: transparent !important;
  padding: 5px 10px 0px 10px;
  border-top: 0px solid transparent;
}

.ca-image-card-footer button {
  padding: 0px;
  font-size: 20px;
  line-height: 20px;
  color: #000000 !important;
}

.ca-image-card-footer button:hover,
.ca-image-card-footer button:active {
  color: #000000 !important;
}

.ca-image-card .ca-novideo-container .ca-novideo {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  height: 250px !important;
}

.ca-image-card .ca-novideo-container .ca-novideo img {
  height: auto;
  width: auto;
}

.ca-image-card .ca-youtube-video-container {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.ca-image-card.ca-image-card-cover {
  padding: 0px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ca-image-card.ca-image-card-cover .ca-youtube-video-container {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ca-text-justify {
  text-align: justify;
}

.ca-infotext-small {
  font-size: 13px;
  color: #999999;
}

.ca-check-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ca-check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.ca-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid #cccccc;
}

.ca-check-container input:checked~.ca-checkmark {
  background-color: #000000;
  border: 1px solid #000000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ca-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ca-check-container input:checked~.ca-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ca-check-container .ca-checkmark:after {
  left: 5px;
  top: 0px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ca-check-label {
  margin-left: 21px;
  line-height: 20px;
  color: #000000;
}

.ca-checkmark-disabled {
  background-color: #999999 !important;
  border: 1px solid #999999 !important;
}

.ca-dark-link,
.ca-dark-link:visited,
.ca-dark-link:focus,
.ca-dark-link:hover,
.ca-dark-link:active {
  color: #000000 !important;
}

.ca-dark-link.disabled {
  cursor: auto;
}

.ca-dark-link.small img {
  height: 30px;
  max-height: 30px !important;
}

.ca-light-link,
.ca-light-link:visited,
.ca-light-link:focus,
.ca-light-link:hover,
.ca-light-link:active {
  color: #ffffff !important;
}

.ca-carousel {
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.ca-carousel,
.ca-carousel .carousel-inner,
.ca-carousel .carousel-inner .carousel-item {
  height: 360px;
  max-width: 640px;
  border-radius: 10px;
}

.ca-carousel.ca-carousel-fluid,
.ca-carousel.ca-carousel-fluid .carousel-inner,
.ca-carousel.ca-carousel-fluid .carousel-inner .carousel-item {
  height: auto;
  max-width: 100%;
}

.ca-carousel .carousel-inner img {
  height: 360px;
  max-width: 640px;
  object-fit: contain;
  object-position: center center;
  border-radius: 10px;
}

.ca-carousel.ca-carousel-big {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.ca-carousel.disabled {
  opacity: 0.5;
}

.ca-carousel.ca-carousel-big,
.ca-carousel.ca-carousel-big .carousel-inner,
.ca-carousel.ca-carousel-big .carousel-inner .carousel-item {
  height: 500px;
  max-width: 100%;
}

.ca-carousel.ca-carousel-big .carousel-inner img {
  height: 500px;
  max-width: 100%;
}

@media (min-width: 992px) and (max-width: 1199.98px) {

  .ca-carousel.ca-carousel-big,
  .ca-carousel.ca-carousel-big .carousel-inner,
  .ca-carousel.ca-carousel-big .carousel-inner .carousel-item {
    height: 400px;
  }

  .ca-carousel.ca-carousel-big .carousel-inner img {
    height: 400px;
  }
}

@media (max-width: 991.98px) {

  .ca-carousel.ca-carousel-big,
  .ca-carousel.ca-carousel-big .carousel-inner,
  .ca-carousel.ca-carousel-big .carousel-inner .carousel-item {
    height: 400px;
    max-width: 640px;
  }

  .ca-carousel.ca-carousel-big .carousel-inner img {
    height: 400px;
    max-width: 640px;
  }
}

@media (max-width: 575.98px) {

  .ca-carousel.ca-carousel-big,
  .ca-carousel.ca-carousel-big .carousel-inner,
  .ca-carousel.ca-carousel-big .carousel-inner .carousel-item {
    height: 400px;
    max-height: 400px;
    width: 100%
  }

  .ca-carousel.ca-carousel-big .carousel-inner img {
    height: 400px;
    max-height: 400px;
    width: 100%
  }
}

.ca-carousel.profile {
  box-shadow: none;
  border-radius: 0px;
}

.ca-carousel.profile,
.ca-carousel.profile .carousel-inner,
.ca-carousel.profile .carousel-inner .carousel-item {
  width: 350px;
  height: 200px;
  border-radius: 0px;
}

.ca-carousel.profile .carousel-inner img {
  width: 350px;
  height: 200px;
  object-fit: cover;
  border-radius: 0px;
}

@media (max-width: 767.98px) {
  .ca-carousel.profile {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .ca-carousel.profile,
  .ca-carousel.profile .carousel-inner,
  .ca-carousel.profile .carousel-inner .carousel-item {
    width: 100% !important;
    height: 400px;
    max-height: 400px;
  }

  .ca-carousel.profile .carousel-inner img {
    width: 100%;
    height: 400px;
    max-height: 400px;
  }
}

.ca-carousel.ca-carousel-fluid .carousel-inner img {
  height: auto;
  max-width: 100%;
}

.ca-carousel-navigator-container {
  height: 70px;
  padding: 25px 5px;
  line-height: 20px;
  background-color: #212121;
}

.ca-carousel-navigator-container.ca-carousel-next {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ca-carousel-navigator-container.ca-carousel-prev {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ca-carousel .carousel-control-prev {
  justify-content: flex-start;
  padding-left: 5px;
  width: 35px;
  height: 70px;
  margin: auto 0;
  opacity: 0.8;
}

.ca-carousel .carousel-control-next {
  justify-content: flex-end !important;
  padding-right: 5px;
  width: 35px;
  height: 70px;
  margin: auto 0;
  opacity: 0.8;
}

/* .ca-carousel .carousel-control-prev .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%777' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.ca-carousel .carousel-control-next .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%777' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
} */

.ca-carousel .carousel-indicators {
  margin-bottom: 5px;
  background-color: #eeeeee;
  opacity: 0.5;
}

.ca-carousel .carousel-indicators li {
  background-color: #777777;
}

.ca-carousel-toolbar-container {
  position: absolute;
  bottom: 5px;
  /* width: 100%; */
  text-align: center;
  font-size: 20px;
  background-color: transparent;
}

.ca-carousel.disabled .ca-carousel-toolbar-container {
  display: none;
}

.ca-carousel-toolbar-control-box {
  background-color: #212121;
  border-radius: 10px;
  opacity: 0.8;
  padding: 5px 10px;
  cursor: pointer;
}

.ca-carousel-toolbar-control {
  color: #ffffff;
  opacity: 1;
}

.ca-carousel .ca-novideo-container .ca-novideo {
  border-radius: 10px;
}

.ca-carousel iframe {
  border-radius: 10px;
}

.ca-form-card {
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 20px 15px;
}

.ca-link_underline a {
  text-decoration: underline !important;
}

.ca-controls-card-footer {
  text-align: right;
  font-size: 20px;
  font-weight: 400;
  min-height: auto !important;
  color: #000000 !important;
}

.card.bg-dark .ca-controls-card-footer {
  color: #ffffff !important;
}

.ca-muted-text {
  color: #999999;
}

.ca-muted-text a,
.ca-muted-text a:visited,
.ca-muted-text a:focus {
  color: #999999;
}

/* react-image-lightbox */
.ReactModal__Overlay--after-open {
  z-index: 1100 !important;
}

.ril-zoom-in {
  outline: none !important;
}

.ril__toolbar {
  background-color: transparent !important;
}

.ca-row-left-column {
  padding-left: 0px;
}

.ca-row-right-column {
  padding-right: 0px;
}

@media (max-width: 991.98px) {
  .ca-row-left-column {
    padding-right: 0px;
  }

  .ca-row-right-column {
    padding-left: 0px;
  }
}

@media (max-width: 767.98px) {
  .ca-row-left-column.col-md-6 {
    padding-right: 0px;
  }

  .ca-row-right-column.col-md-6 {
    padding-left: 0px;
  }
}

@media (max-width: 575.98px) {
  .ca-row-left-column.col-sm-6 {
    padding-right: 0px;
  }

  .ca-row-right-column.col-sm-6 {
    padding-left: 0px;
  }
}

.ca-artworks-modal {
  width: 90% !important;
  height: 95vh !important;
  max-height: 95vh !important;
  margin: auto;
}

@media (max-width: 575.98px) {
  .ca-artworks-modal {
    width: 95% !important;
  }
}

.ca-artworks-modal.modal-dialog {
  max-width: none;
}

.ca-card-image-only {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  border: 0px;
  margin-right: 0px;
  margin-bottom: 0.75rem;
  width: 100%;
}

.ca-card-image-only img {
  border-radius: 10px;
}

.ca-image-card-container {
  text-align: center;
  margin: auto;
  display: inline-block;
  position: relative;
  border-radius: 10px;
}

.ca-image-card-container.ca-left {
  text-align: left;
  margin-left: 0px;
}

.ca-image-card-container img {
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 10px;
  margin: auto;
  cursor: pointer;
  max-height: 400px;
  max-width: 100%;
}

.ca-image-card-container-hover {
  transition: .5s ease;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  display: none;
  font-size: 14px;
}

.ca-image-card-container:hover img {
  opacity: 0.3;
}

.ca-image-card-container:hover .ca-image-card-container-hover {
  opacity: 1;
  display: block;
}

.ca-image-card-container img {
  transition: 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.ca-image-card-container:hover img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.ca-homepage-icon-container {
  width: 100%;
  text-align: center;
}

.ca-homepage-icon-container.disabled {
  color: #999999;
}

.ca-homepage-icon {
  max-width: 150px;
}

.ca-homepage-icon.disabled {
  opacity: 0.4;
}

.ca-homepage-icon-title {
  margin-top: 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.ca-homepage-icon-title-soon {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.ca-image-container {
  text-align: center;
  margin: auto;
  display: inline-block;
  width: 100%;
}

.ca-image-container img {
  display: block;
  height: auto;
  transition: .5s ease;
  border-radius: 10px;
  margin: auto;
  max-height: 500px;
  max-width: 100%;
  transition: 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.ca-page-title-icon {
  height: 60px;
  margin-right: 8px;
}

@media (max-width: 575.98px) {
  .ca-page-title-icon {
    height: 40px;
    margin-right: 4px;
  }
}

.ca-action-icon-30 {
  font-size: 30px;
}

.ca-action-icon-35 {
  font-size: 35px;
}

@media (max-width: 575.98px) {
  .ca-action-icon-30 {
    font-size: 20px;
  }

  .ca-action-icon-35 {
    font-size: 25px;
  }
}

.ca-model-viewer {
  width: 450px;
  height: 450px;
  margin-left: auto;
  margin-right: auto;
}

.ca-model-viewer-panel {
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-width: 640px;
}

.ca-model-viewer-panel .ca-form-object-container {
  width: 100%;
  border-radius: 10px;
}

.ca-model-viewer-carousel {
  width: 500px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

.ca-model-viewer-small {
  width: 350px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575.98px) {
  .ca-model-viewer {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 1199.98px) {
  .ca-model-viewer-carousel {
    width: 350px;
    height: 400px;
  }
}

.ca-vips-button {
  color: #ffffff;
  background-color: transparent;
  white-space: nowrap;
  border: 0px;
  vertical-align: middle !important;
  height: 100%;
  text-transform: uppercase !important;
}

.ca-pay-button {
  color: #ffffff;
  background-color: #000000;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  padding: 7px 25px 7px 25px !important;
  border-radius: 20px !important;
  white-space: nowrap;
  border: 1px solid #000000 !important;
  vertical-align: middle !important;
  height: 44px;
  max-height: 44px !important;
  min-width: 100px;
  text-transform: uppercase !important;
}

.ca-dashboard-card {
  border-radius: 10px;
  margin-bottom: 20px;
}

.ca-dashboard-card .card-header {
  background-color: #000000;
  color: #ffffff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
}

.ca-dashboard-card .card-header .card-title {
  margin-bottom: 0px;
}

.ca-dashboard-card .card-body {
  padding: 13px 10px;
  height: 500px;
}

@media (max-width: 575.98px) {
  .ca-dashboard-card .card-body {
    height: 100%;
  }
}

.ca-dashboard-card .art-image-col {
  text-align: center;
  width: 170px;
  max-width: 170px;
}

.ca-dashboard-card .art-image {
  max-width: 150px;
  max-height: 150px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .ca-dashboard-card .art-image-col {
    width: 140px;
    max-width: 140px;
  }

  .ca-dashboard-card .art-image {
    max-width: 120px;
    max-height: 120px;
  }
}

.ca-dashboard-card .art-desc-col {
  max-width: 250px;
}

.ca-dashboard-card .art-author {
  color: #AAAAAA;
}

.ca-dashboard-card .art-graph-col {
  width: calc(100% - 320px);
}

@media (max-width: 575.98px) {
  .ca-dashboard-card .art-graph-col {
    display: none;
  }
}

.ca-dashboard-card .dashboard-table {
  border: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.ca-dashboard-card .dashboard-table tr {
  height: 75px;
}

@media (max-width: 575.98px) {
  .ca-dashboard-card .dashboard-table {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ca-dashboard-card .dashboard-table tr {
    height: 50px;
  }
}

.ca-dashboard-card .dashboard-table th,
.ca-dashboard-card .dashboard-table td {
  border: 0px;
}

.ca-dashboard-card .dashboard-no-data-container {
  width: 100%;
  height: 300px;
  background-color: #000000;
  color: #ffffff;
  font-size: 20px;
}

.ca-dashboard-card .dashboard-no-data-container a {
  color: #ffffff;
  text-decoration: underline !important;
  font-size: 20px;
}

.ca-publish-page {
  flex: 1 1 auto;
  padding: 0 !important;
  overflow-y: auto;
  height: 100px;
  /* flex will automatically grow to fill available height */
}

.ca-artwork-print-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.ca-box-shadow-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.ca-table-icon {
  font-size: 20px;
  color: #000000;
}

.ca-table-image-icon {
  height: 25px;
}

.no-padding {
  padding: 0;
}

.ca-autocomplete {
  margin-bottom: 5px;
}

.ca-autocomplete .select__control {
  border-radius: 10px;
}

.ca-autocomplete .select__menu {
  z-index: 9;
}

.ca-tooltip.tooltip {
  opacity: 1 !important;
}

.ca-tooltip.tooltip .tooltip-inner {
  max-width: 500px !important;
  background-color: #555555 !important;
}

@media (max-width: 575.98px) {
  .ca-tooltip.tooltip .tooltip-inner {
    max-width: 350px !important;
  }
}

.ca-tooltip.tooltip.text-left .tooltip-inner {
  text-align: left !important;
}

.ca-company-logo-container {
  width: 180px;
  height: 180px;
  position: relative;
}

@media (max-width: 575.98px) {
  .ca-company-logo-container {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
  }
}

.ca-company-logo-container.no-logo {
  background-color: #000000;
}

.ca-company-logo-container:hover img {
  opacity: 0.2;
}

.ca-company-logo-container:hover.view img {
  opacity: 1;
}

.ca-company-logo {
  width: 180px;
  max-height: 180px;
  object-fit: contain;
  object-position: center;
  text-align: center;
}

.ca-company-logo-change {
  transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  display: none;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.ca-company-logo-container:hover .ca-company-logo-change {
  display: block;
  opacity: 1;
}

.ca-company-space-detail-container {
  background-color: #000000;
  color: #FFFFFF;
  padding: 15px;
  font-size: 15px;
  margin-left: 20px;
}

@media (max-width: 575.98px) {
  .ca-company-space-detail-container {
    height: 180px;
    margin-left: 0px;
  }
}

.card .more-info-button {
  cursor: pointer;
  color: #999999;
  text-align: right;
  font-size: 15px;
}

.ca-art-modal .modal-title {
  font-size: 20px;
}

.ca-art-modal .modal-body .modal-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.ca-art-modal .modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ca-profile-img-item-container {
  width: 100%;
  text-align: center;
}

.ca-profile-img-item-container img {
  width: 150px;
  height: 150px;
}

.ca-profile-img-item-container .title {
  margin-top: 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.ca-company-logo-item-container {
  width: 100%;
  text-align: center;
}

.ca-company-logo-item-container .img-container {
  width: 150px;
  height: 150px;
  max-height: 150px;
  overflow: hidden;
}

.ca-company-logo-item-container .img-container img {
  width: auto;
  height: auto;
  max-width: 150px;
  max-height: 150px;
}

.ca-company-logo-item-container .title {
  margin-top: 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.ca-profile-detail-container {
  background-color: #000000;
  color: #FFFFFF;
  padding: 15px;
  font-size: 15px;
  margin-left: 0px;
}

@media (max-width: 575.98px) {
  .ca-profile-detail-container {
    height: 180px;
    margin-left: 0px;
    text-align: center;
  }
}

.ca-profile-detail-container .actions {
  font-size: 20px;
  font-weight: 400;
}

.ca-profile-detail-container .actions span {
  cursor: pointer;
}

.ca-profile-name {
  font-weight: 700;
  margin-bottom: 15px;
}

.ca-profile-membersince {
  color: #AAAAAA;
}

.ca-profile-social-container {
  font-size: 36px;
  width: 100%;
  margin-bottom: 2rem;
  height: 54px;
}

@media (max-width: 575.98px) {
  .ca-profile-social-container {
    text-align: center;
    font-size: 36px;
  }
}

.ca-profile-social-container span {
  cursor: pointer;
}

.ca-profile-web-resources-container {
  font-size: 18px;
}

.ca-profile-video {
  width: 350px !important;
  height: 200px !important;
  margin: auto;
  position: absolute;
  right: 25px;
  top: -220px;
  border: 2px solid #FFFFFF;
}

@media (max-width: 991.98px) {
  .ca-profile-video {
    position: relative;
    right: auto;
    top: auto;
    border: 0;
  }
}

.ca-profile-video .no-content {
  background-color: #000000;
}

.ca-profile-media {
  width: 354px !important;
  height: 220px !important;
  margin: auto;
  position: absolute;
  right: 25px;
  top: -240px;
  border: 2px solid #FFFFFF;
}

.ca-profile-media .presentation-image {
  width: 350px;
  height: 200px;
  object-fit: cover;
}

.ca-profile-media .no-content {
  background-color: #000000;
}

@media (max-width: 991.98px) {
  .ca-profile-media {
    position: relative;
    right: auto;
    top: auto;
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .ca-profile-media {
    max-width: 640px !important;
    width: 100% !important;
    height: 400px !important;
  }

  .ca-profile-media .presentation-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}

.ca-ar-art-publish-preview-image {
  width: auto;
  max-width: 100%;
  max-height: 600px;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .ca-ar-art-publish-preview-image {
    max-height: 400px;
  }
}

.ca-ar-art-publish-preview-print-container img {
  width: auto;
  max-width: 100%;
  max-height: 400px;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .ca-ar-art-publish-preview-print-container {
    text-align: center;
  }
}

.ca-icon {
  width: 40px;
}

.park-call-apply-art-card {
  text-align: center;
}

.park-call-apply-art-card img {
  padding: 5px;
  width: 70px !important;
}

.ca-modal-90w {
  width: 90vw !important;
  max-width: 100vw;
}

.ca-modal-80w {
  width: 80vw !important;
  max-width: 100vw;
}

@media (max-width: 575.98px) {

  .ca-modal-90w,
  .ca-modal-80w {
    width: 97vw !important;
  }
}


/* entity card */
.ca-entity-card.card {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .ca-entity-card.card {
    margin-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ca-entity-card.card {
    margin-right: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ca-entity-card.card {
    margin-right: 20px;
  }
}

@media (max-width: 575.98px) {
  .ca-entity-card.card {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.ca-entity-card .ca-card-image-container {
  width: 100%;
  height: 300px;
  max-height: 300px;
  overflow: hidden;
  text-align: center;
}

.ca-entity-card.card .ca-card-image-container img {
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px;	
  object-fit: cover; 
  object-position: center; */
  width: auto;
  height: auto;
  max-width: 96%;
  max-height: 300px;
}

.ca-entity-card.card .card-body {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.ca-entity-card.card .card-title {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  min-height: 40px;
  margin-bottom: 8px;
}

.ca-entity-card.card .card-footer {
  border: 0px solid #ffffff;
  background-color: transparent;
  padding: 10px 10px 10px 10px
}

.ca-entity-card.card .card-footer .form-check-input {
  margin-top: 0;
  width: 16px;
  height: 16px;
  background-color: #000000 !important;
}

.ca-entity-card.card .card-footer .form-check-label {
  color: #999999;
  padding-top: 2px;
}

.ca-entity-card-footer {
  text-align: right;
  font-size: 20px;
}
.App {
  text-align: center;
}

body {
  font-size: 15px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.main-container {
  padding: 0 3.5rem;
}

@media (max-width: 992px) {

  .main-container {
    padding: 0 2.5rem;
  }

}

.routing-link,.routing-link:hover,.routing-link:active {
  text-decoration: none;
  color: white;
}

.ca-navbar {
    background-color: black;
    height: 4.5rem;
    min-width: 100%;
    display: flex;
    flex-direction: row;
}

#ca-navbar .nav-link { 
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
    text-transform: uppercase;
}

#ca-navbar .nav-link, #ca-navbar .dropdown-menu {
    color: rgba(255,255,255);
    /* font-weight: ; */
    font-size: 1rem;
    letter-spacing: 0.02rem;
    margin-left: 12px;
}

#ca-navbar .dropdown-menu {
    margin-top: 16px;
    background-color: black;
    border-radius: 10px;
}

#ca-navbar .dropdown-item {
    color: white;
    font-size: 15px;
    font-weight: 400;
    padding-left: 10px;
    min-width: 200px;
}

#ca-navbar .nav-link::after {
    display: none;
}

.navbar-brand {
    margin-left: 1rem;
}

.ca-upgrade-button {
    margin-left: 20px;
}

.ca-upgrade-menuitem {
    display: none;
}

.ca-cart {
    margin: 0px 20px 0px 20px !important;
    color: #ffffff;
}

.ca-cart a {
    color: #ffffff !important;
}

.ca-nav-right {
    align-items: center;
}

/* @media (min-width: 1550px) {
    .navbar-custom-nav .nav-link, .navbar-custom-nav .dropdown-menu{
        margin-left: 2.5vw; 
    }
} */

.search-input {
    max-height: 2.5rem;
    max-width: 12.5vw;
    /* margin-left: 10vw; */
    margin-left: 7vw;
    background-color: black;
    font-size: 1rem;
    border: 2px solid white;
    font-family: Roboto, 'Open Sans', 'FontAwesome', sans-serif;
    padding-bottom: 0.55rem;
    border-radius: 5rem;
}

.search-input:focus {
    color: white;
    background-color: black;
}

.search-input::-webkit-input-placeholder {
    color: white;
}

/* .upgrade-button {
    border-radius: 4mm;
    font-weight: bolder;
    font-size: 1vw;
    margin-left: 3.5vw
    margin-left: 1.5vw
} */

.navbar-dropdown-button-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
}

.navbar-user {
    letter-spacing: normal;
    padding-left: 1.5rem;
    font-size: 1.1rem;
}

#ca-navbar .navbar-user-and-icon .nav-link {
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;
}

#ca-navbar .navbar-user-and-icon .dropdown-menu {
    margin-top: 18px;
}

.profile-icon {    
    margin-left: 8px;
    margin-right: 2px;
    background-color: none;
    /* border: 1px solid white; */
    /* border-radius: 50%; */
    /* display: flex; */
    /* align-items: center; */    
}

.navbar-collapse.collapse.show {
    background-color: black;
}

#nav-left {
    order: 0;
}

#nav-middle {
    order: 1;
}

#upgrade-button {
    order: 2;
    margin-left: 20px;
    justify-content: flex-end;
}

#nav-right {
    order: 3;
}

#ca-responsive-navbar {
    display: none;
}

.navbar-custom-nav .dropdown-item, .navbar-user-and-icon .dropdown-item {
    background-color: black;
}

.custom-nav-dropdown.dropdown.nav-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

#ca-navbar .nav-link {
    display: flex;
    align-items: center;
}

.ca-nav-username {
    display: none;
}

.ca-navbar-brand a {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    color: #000000;
}

@media (min-width: 992px) {

    .navbar-expand-xl .navbar-nav {
        width: 100%;
        justify-content: space-around;
    }

    #nav-right {
        order: 2;
        display: flex;
        justify-content: flex-end;
    }
    
    #ca-navbar .navbar-user-and-icon .nav-link {
        margin: 0;
        padding: 0;
    }

    .navbar-user {
        padding: 0;
    }

    #ca-navbar .languages .nav-link{
        padding: 0;
        margin: 0;
    }

    #nav-middle {
        margin: 0;
    }

    #ca-navbar .nav-link .navbar-custom-link {
        margin: 0;
    }

}

/* @media (max-width: 1080px) {
    .navbar-expand-lg {
        flex-flow: row nowrap !important;
        justify-content: flex-start !important;
    }
} */

@media (max-width: 991.98px) {
    #ca-navbar .hidden-sm {
        display: none !important;
    }

    .ca-navbar {
        justify-content: space-evenly;
    }

    .ca-navbar-brand {
        width: calc(100% - 160px);
        text-align: center;
    }

    .navbar-brand {
        margin-right: 0;
        margin-left: 0;
    }

    .navbar {
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
    }

    .navbar-custom-nav {
        padding: 12px;
    }

    #nav-middle {
        order: 0;
        margin-left: 0;
        min-width: 30vw;
    }

    #upgrade-button {
        order: 1;
        margin-left: 0;
        z-index: 10;
    }

    #upgrade-button.collapsing {
        display: none;
    }

    /* .upgrade-button-collapsed {
        margin-left: 1.5vw;
        margin-top: 0.6rem;
    } */
    
    #nav-left {
        order: 2;
        z-index: 10;
    }

    #nav-left.collapsing {
        display: none;
    }
    
    #nav-right {
        order: 2;
        padding-top: 0.25rem;
        padding-bottom: 0.35rem;
        z-index: 10;
    }

    #nav-right .navbar-user-and-icon .dropdown-toggle {
        margin-left: 0;
        padding-left: 0;
        /* padding-top: 0.6rem; */
    }

    #nav-right.collapsing {
        display: none;
    }

    #nav-right .upgrade-button {
        margin-left: 0;
        font-size: medium;
        border-radius: 5mm;
        margin-left: 6vw;
    }

    #nav-right .navbar-user {
        padding-left: 0;
        font-size: 15px;
        padding-top: 0.5vh;
    }

    #nav-right .big-icon {
        font-size: 15px;
    }

    #ca-navbar .navbar-user-and-icon .dropdown-menu {
        margin-top: 0px;
    }

    #ca-navbar .navbar-user-and-icon .dropdown-menu {
        width: 100%;
        border: none;
    }

    .navbar-nav {
        padding-top: 0.3rem;
    }

    #ca-navbar .dropdown .show {
        padding-left: 15px;
    }

    #ca-responsive-navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;;
        width:100%;
        padding-left: 4vw;
        padding-right: 4vw;
    }

    #ca-navbar .nav-link, #ca-navbar .dropdown-menu {
        color: rgba(255,255,255);
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.15rem;
        margin-left: 0px;
    }

    #ca-navbar .dropdown-menu {
        background-color: #ffffff;
        border-radius: 4px;
        margin-top: 5px;
        width: 100%;
        padding: 0;
        /* margin: 0; */    
    }

    .navbar-custom-nav .dropdown-item {
        color: #000000 !important;
        background-color: #ffffff;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    #ca-navbar .nav-link { 
        padding-bottom: 0rem;
        padding-top: 0.6rem;
    }

    #ca-navbar .custom-nav-dropdown.administration {
        margin-bottom: 0.2rem;
    }
    
    #ca-navbar .custom-nav-dropdown.administration .nav-link, #ca-navbar .custom-nav-dropdown.artworks .nav-link {
        padding-bottom: 0;
    }

    #ca-navbar div.dropdown-menu::show {
        margin: 0;
    }

    #ca-navbar .dropdown-item {
        /* color: #000000; */
        font-size: 15px;
        font-weight: 400;
        padding-left: 0px;
    }

    .ca-nav-right {        
        flex-direction: row;
    }

    .ca-nav-right .dropdown-item {
        color: #ffffff;
        text-align: right;
        padding-right: 12px;
    }

    .ca-nav-right .dropdown .show {
        padding-left: 15px !important;
        background-color: #000000 !important;
        border-radius: 0px !important;
    }

    .ca-nav-right .dropdown-menu.show {
        position: absolute;
        min-width: 100vw;
        left: calc(-100vw + 62px) !important;
        top: 57px !important;
        right: 0;
    }

    .ca-cart {
        margin: 0 5px 0 0 !important;
    }
    
    .ca-nav-username {
        display: inline-block;
        text-align: right;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        padding-right: 12px;
        padding-bottom: 5px;
    }

    .ca-upgrade-menuitem {
        width: 100%;
        text-align: right;
        display: inline-block;
        color: #000000 !important;
        margin-bottom: 5px;        
    } 

    .ca-upgrade-menuitem-container {
        background-color: #ffffff !important;
        margin-right: 12px !important;
        padding: 4px 2px;
        border-radius: 4px;
    }

    .ca-upgrade-menuitem a {
        color: #000000 !important;
    }
    
    .ca-navbar .navbar-toggler {
        border: 0px;
    }

    .ca-navbar .navbar-toggler-icon {
        background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }    
}
.ca-artwork-column-left {
  padding-left: 0px;
}

.ca-artwork-column-right {
  padding-right: 0px;
}

@media (max-width: 991.98px){
  .ca-artwork-column-left {
      padding-right: 0px;
  } 
  
  .ca-artwork-column-right {
      padding-left: 0px;
  }    
}
.ca-table-geofences .ReactTable .rt-thead {
  min-width: 350px !important;
}

.ca-table-geofences .ReactTable .rt-tbody {
  min-width: 350px !important;
}

.ca-table-geofences .ReactTable .rt-tr .rt-th:first-child {
  width: 35% !important;
}

.ca-table-geofences .ReactTable .rt-tbody .rt-td:first-child {
  width: 35% !important;
  white-space: normal;
}


.ca-table-geofences .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 50% !important;
}

.ca-table-geofences .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 50% !important;
}

.ca-table-geofences .ReactTable .rt-tr .rt-th:last-child {
  width: 15% !important;
}

.ca-table-geofences .ReactTable .rt-tbody .rt-td:last-child {
  width: 15% !important;
}
.artwork-owner {
    font-weight: normal;
    font-size: 1rem;
    color: rgba(170, 170, 170, 1);
}
.ca-table-arPortal .ReactTable .rt-thead {
  min-width: 350px !important;
}

.ca-table-arPortal .ReactTable .rt-tbody {
  min-width: 350px !important;
}

.ca-table-arPortal .ReactTable .rt-tr .rt-th:first-child {
  width: 35% !important;
}

.ca-table-arPortal .ReactTable .rt-tbody .rt-td:first-child {
  width: 35% !important;
}


.ca-table-arPortal .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 60% !important;
}

.ca-table-arPortal .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 60% !important;
}

.ca-table-arPortal .ReactTable .rt-tr .rt-th:last-child {
  width: 5% !important;    
}

.ca-table-arPortal .ReactTable .rt-tbody .rt-td:last-child {
  width: 5% !important;
  justify-content: flex-start !important;
  white-space: normal;
  text-align: right;
}
.ca-artwork-buy-modal .modal-title {
    font-size: 20px;
}

.ca-artwork-buy-modal .modal-body .modal-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.ca-artwork-buy-modal .modal-body .ca-modal-title-detail {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
}

.ca-artwork-buy-modal .modal-footer {
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
}

.ca-buy-modal-price {
    margin-bottom: 20px;
    font-size: 16px;
}

.ca-buy-modal-price .ca-price-detail {
    font-size: 16px;
    color: #999999;
}

.ca-buy-modal-pkg {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}

.ca-buy-modal-print {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}
.create-project-card.card {
    border-radius: 20px;
    height: 300px;
    margin-right: 30px;
    color: #ffffff;
    background-color: #000000;
    margin-bottom: 15px;
    width: 100%;
    border: 0px;
    text-align: center;
    align-items: center;
}

@media (max-width: 991.98px){
    .create-project-card.card {
        margin-right: 0;
    }    
}

.create-project-card.card:hover {
    cursor: pointer;
}

.create-project-card.card .card-body {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 10px;
}

.create-project-card.card .card-title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
}

.create-project-card.card .card-title:hover {
    color: #ffffff !important;
}

.create-project-card.card .card-text {
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
}

@media (min-width: 992px) and (max-width: 1199.98px){
    .create-project-card.card .card-title {
        font-size: 22px;
        line-height: 24px;
    }    
    .create-project-card.card .card-text {
        font-size: 18px;
        line-height: 20px;
    }    
}

.create-project-card a {
    all: unset;
    display: flex;
    flex: 1 0;
}

.create-project-card.disabled {
    /* background-color: rgba(150, 150, 150, 1); */
    background-color: rgba(0, 0, 0, 1);
}

.create-project-card.disabled:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
}

.create-project-card.disabled:hover {
    cursor: default;
}

.create-project-card img {
    padding-top: 15px;
    width: 100px !important;    
}
/* .project-create-modal .btn {
    border-radius: 4mm;
    font-weight: bolder;
    font-size: large;
    background-color: black;
    color: white;
    border-color: black;
}

.project-create-modal .btn:hover {
    background-color: black;
    border-color: black;
} */

.project-create-modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 17.5rem;
}

.project-create-modal .modal-title {
    font-weight: bolder;
    font-size: x-large;
}

.project-create-modal p {
    font-size: large;
}

.project-create-modal-arrange-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ca-project-photo-container {
    max-width: 250px;
    margin-right: 15px;
    padding: 0;
}

.ca-project-photo {
    width: 250px;
    border-radius: 10px;
}

@media (max-width: 575.98px) {
    .ca-project-photo-container {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .ca-project-photo {
        width: 100%;
    }    
}

.ca-project-title-container {
    padding-left: 0;
    min-width: calc(100% - 265px);
}

.ca-project-title-name {
    font-size: 24px;
    font-weight: 700;
}

.ca-project-title-category {
    font-size: 18px;
    font-weight: 400;
    color: #999999;
}

.ca-project-title-description {
    font-size: 15px;
    font-weight: 400;
    /* color: #999999; */
}

.ca-project-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.ca-edit-project-photo-text {
    color: #999999;
    font-size: 13px;
}

.ca-edit-project-photo-icon {
    color: #999999;
    font-size: 18px;
}

* {
    text-decoration: none !important;
}

.ReactTable .rt-noData {
    margin-top: 1.5rem;
    background: none !important;
}

.stepper-button {
    border-radius: 3mm;
    min-height: 2.2rem;
    height: 2.2rem;
    font-weight: bold;
    font-size: 1rem;
    background-color: black;
    color: white !important;
    border-color: black;
    padding:0;
}

.back-next {
    min-width: 5rem;
}

.save-changes {
    min-width: 9rem;;
}
    
.stepper-button:hover {
    background-color: black;
    border-color: black;
}

.photo-publish-col-direction-column {
    display: flex;
    flex-direction: column;
}

.photo-publish-page-title {
    font-size: x-large;
    font-weight: 900;
}

.photo-publish-page-sub-title {
    font-weight: bold;
    font-size: medium;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
    margin-top: -0.2rem;
    color: darkgrey;
}

.photo-publish-page-section-title {
    font-size: large;
    font-weight: bold;
}

.photo-publish-form {
    margin-top: 0.5rem;
}

.photo-publish-form .form-label {
    font-weight: bold;
    margin-left: 0.5rem;
}

.photo-publish-form .form-control {
    width: 15rem;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
}

.photo-first-step-column {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ca-publish-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ca-publish-section-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 2px;
}

.ca-publish-section-subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #999999;
    margin-bottom: 10px;
}

.photo-first-step-artwork-details-form {
    margin-top: 1rem;
}

.photo-first-step-artwork-details-form .form-control {
    min-width: 20rem;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
}

.photo-second-step-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.photo-second-step-column-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.photo-second-step-dropzone-title {
    font-weight: 800;
    font-size: x-large;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
}

.photo-second-step-dropzone-sub-title {
    font-weight: bold;
    font-size: medium;
    -webkit-text-decoration: underline white;
            text-decoration: underline white;
    margin-top: -0.6rem;
    color: darkgrey;
}

.ca-artwork-publish-modal .modal-body .modal-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.ca-artwork-publish-modal .modal-body .description {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
}

.ca-artwork-publish-modal .modal-footer .second-col {
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
}

.ca-artwork-publish-modal a {
    text-decoration: underline !important;
    font-weight: 700;
    font-size: 13px;
}
.display-in-portofolio-modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 17.5rem;
}

.display-in-portofolio-modal .modal-title {
    font-weight: 700;
    font-size: 1.35rem;
    text-align: center;
}

.display-in-portofolio-modal p {
    font-size: large;
}

.display-in-portofolio-modal-arrange-text {
    display: flex;
    flex-basis: 75%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.ca-display-portfolio-modal .modal-footer {
    display: flex; 
    flex-direction: row;
    justify-content: space-evenly;
}
.ca-artwork-column-left {
    padding-left: 0px;
}

.ca-artwork-column-right {
    padding-right: 0px;
}

@media (max-width: 991.98px){
    .ca-artwork-column-left {
        padding-right: 0px;
    } 
    
    .ca-artwork-column-right {
        padding-left: 0px;
    }    
}
/* .card-columns {
    width: 100%;
    column-count: 4;}
    @media (min-width: 768px) and (max-width: 1199.98px) {
        .card-columns {
            column-count: 3;}
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
        .card-columns {
            column-count: 2;}
    }
    @media (max-width: 575.98px) {
        .card-columns {
            column-count: 1;}
    }     */

.ca-artwork-column-left {
  padding-left: 0px;
}

.ca-artwork-column-right {
  padding-right: 0px;
}

@media (max-width: 991.98px){
  .ca-artwork-column-left {
      padding-right: 0px;
  } 
  
  .ca-artwork-column-right {
      padding-left: 0px;
  }    
}

.ca-table-user-company .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-user-company .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-user-company .ReactTable .rt-tr .rt-th:first-child {
  width: 55% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:first-child {
  width: 55% !important;
}


.ca-table-user-company .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 10% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 10% !important;
}


.ca-table-user-company .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-user-company .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-end !important;
  white-space: normal;
}
.ca-table-user-company .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-user-company .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-user-company .ReactTable .rt-tr .rt-th:first-child {
  width: 55% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:first-child {
  width: 55% !important;
}


.ca-table-user-company .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 10% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 10% !important;
}


.ca-table-user-company .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-user-company .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-end !important;
  white-space: normal;
}
.ca-table-company-address .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-company-address .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-company-address .ReactTable .rt-tr .rt-th:first-child {
  width: 25% !important;
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:first-child {
  width: 25% !important;
}


.ca-table-company-address .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 50% !important;
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 50% !important;
}


.ca-table-company-address .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-company-address .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-end !important;
  white-space: normal;
}
.no-margin {
    display: flex;
    flex-direction: row;
    margin: 0;
}

.no-padding {
    padding: 0;
}

@media (min-width: 1200px){
    .no-padding{
        max-width: 100%;
    }
}

@media (min-width: 1312px){
    .no-padding{
        max-width: 80%;
    }
}

@media (min-width: 1482px){
    .no-padding{
        max-width: 70%;
    }
}


.m-right10{
    margin-right: 2rem;
}

.add-users-button {
    border-radius: 4mm;
    font-weight: bolder;
    font-size: large;
    background-color: black;
    color: white;
    border-color: black;
}

.add-users-button:hover {
    color: white;
    background-color: black;
    border-color: black;
}

.input.users-search.form-control {
    padding: 0;
}

/* .users-search {
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    border: 1px solid #cccccc;
    border-radius: 10px;
} */

.dropdown-toggle::after{
    position: relative;
}

table {
    border-spacing: 0;
    border: 1px solid black;
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

tr {
    height: 3rem;
}

th,
td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid lightgrey;
    border-right: 0;
}

td:last-child {
    border-right: 0;
}

.pagination {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

.marginright-1rem {
    margin-right: 1rem;
}

.pagination-button {
    border-radius: 20%;
    text-align: center;
}

.arrange-page-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page-input {
    width: 4rem;
}

.table-column-id {
    width: 5rem;
}

.table-column-name {
    width: 30rem;
}

.table-column-username {
    width: 30rem;
}

.table-column-type {
    width: 15rem;
}

.table-column-membership {
    width: 15rem;
}

.table-column-status {
    width: 10rem;
}

.table-column-caret {
    width: auto;
    color: black;
    font-size: 1.5rem;
    padding: 0;
    font-weight: bolder;
    text-align: right;
    margin-right: 1rem;
}

.sort-caret {
    font-size: 1rem;
    margin-left: 0.5rem;
}

/* .users-status-dropdown {
    margin-right: 2rem;
} */







.ca-table-sales .ReactTable .rt-thead {
  min-width: 768px !important;
}

.ca-table-sales .ReactTable .rt-tbody {
  min-width: 768px !important;
}

.ca-table-sales .ReactTable .rt-tr .rt-th:first-child {
  width: 15% !important;
}

.ca-table-sales .ReactTable .rt-tbody .rt-td:first-child {
  width: 15% !important;
}


.ca-table-sales .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 10% !important;
}

.ca-table-sales .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 10% !important;
}


.ca-table-sales .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-sales .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-sales .ReactTable .rt-tr .rt-th:nth-of-type(4) {
  width: 10% !important;
}

.ca-table-sales .ReactTable .rt-tbody .rt-td:nth-of-type(4) {
  width: 10% !important;
}

.ca-table-sales .ReactTable .rt-tr .rt-th:nth-of-type(5) {
  width: 10% !important;
}

.ca-table-sales .ReactTable .rt-tbody .rt-td:nth-of-type(5) {
  width: 10% !important;
}

/* .ca-table-sales .ReactTable .rt-tr .rt-th:nth-of-type(6) {
  width: 30% !important;
}

.ca-table-sales .ReactTable .rt-tbody .rt-td:nth-of-type(6) {
  width: 30% !important;
} */

.ca-table-sales .ReactTable .rt-tr .rt-th:last-child {
  width: 40% !important;    
}

.ca-table-sales .ReactTable .rt-tbody .rt-td:last-child {
  width: 40% !important;
  justify-content: flex-start !important;
  white-space: normal;
}
.membership-benefits {
  list-style: none;
  counter-reset: li;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
  padding-right: 10px;
  /* min-height: 550px; */
}

.membership-benefits>li::before {
  content: counter(li);
  background-color: black;
  color: white;
  display: inline-block;
  width: 1.5em;
  text-align: center;
  margin-left: -1em;
  border-radius: 10rem;
  margin: 5px;  
}

.membership-benefits.current>li::before {
  background-color: #ffffff;
  color: #000000;
}

.membership-benefits>li {
  counter-increment: li;
}

.membership-benefits.title-only>li {
  margin-bottom: 20px;
}
.ca-table-company .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-company .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-company .ReactTable .rt-tr .rt-th:first-child {
  width: 30% !important;
}

.ca-table-company .ReactTable .rt-tbody .rt-td:first-child {
  width: 30% !important;
}

.ca-table-company .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 20% !important;
}

.ca-table-company .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 20% !important;
}

.ca-table-company .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-company .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-company .ReactTable .rt-tr .rt-th:nth-of-type(4) {
  width: 15% !important;
}

.ca-table-company .ReactTable .rt-tbody .rt-td:nth-of-type(4) {
  width: 15% !important;
}

.ca-table-company .ReactTable .rt-tr .rt-th:nth-of-type(5) {
  width: 10% !important;
}

.ca-table-company .ReactTable .rt-tbody .rt-td:nth-of-type(5) {
  width: 10% !important;
}

.ca-table-company .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
  min-width: 135px;
}

.ca-table-company .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  min-width: 135px;
  justify-content: flex-end !important;
  white-space: normal;
}
.ca-table-user-company .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-user-company .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-user-company .ReactTable .rt-tr .rt-th:first-child {
  width: 55% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:first-child {
  width: 55% !important;
}


.ca-table-user-company .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 10% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 10% !important;
}


.ca-table-user-company .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-user-company .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-user-company .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-end !important;
  white-space: normal;
}
.ca-table-company-address .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-company-address .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-company-address .ReactTable .rt-tr .rt-th:first-child {
  width: 25% !important;
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:first-child {
  width: 25% !important;
}


.ca-table-company-address .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 50% !important;
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 50% !important;
}


.ca-table-company-address .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-company-address .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-company-address .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-end !important;
  white-space: normal;
}
.ca-table-company-artist .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-company-artist .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-company-artist .ReactTable .rt-tr .rt-th:first-child {
  width: 55% !important;
}

.ca-table-company-artist .ReactTable .rt-tbody .rt-td:first-child {
  width: 55% !important;
}

.ca-table-company-artist .ReactTable .rt-tr .rt-th:last-child {
  width: 20% !important;    
}

.ca-table-company-artist .ReactTable .rt-tbody .rt-td:last-child {
  width: 20% !important;
  justify-content: flex-start !important;
  /* white-space: normal; */
}
.ca-table-gift .ReactTable .rt-thead {
  min-width: 768px !important;
}

.ca-table-gift .ReactTable .rt-tbody {
  min-width: 768px !important;
}

.ca-table-gift .ReactTable .rt-tr .rt-th:first-child {
  width: 30% !important;
}

.ca-table-gift .ReactTable .rt-tbody .rt-td:first-child {
  width: 30% !important;
}


.ca-table-gift .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 30% !important;
}

.ca-table-gift .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 30% !important;
}


.ca-table-gift .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 20% !important;
}

.ca-table-gift .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 20% !important;
}


.ca-table-gift .ReactTable .rt-tr .rt-th:nth-of-type(4) {
  width: 10% !important;
}

.ca-table-gift .ReactTable .rt-tbody .rt-td:nth-of-type(4) {
  width: 10% !important;
}


.ca-table-gift .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-gift .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-start !important;
  white-space: normal;
}
.ca-table-billing .ReactTable .rt-thead {
  min-width: 768px !important;
}

.ca-table-billing .ReactTable .rt-tbody {
  min-width: 768px !important;
}

.ca-table-billing .ReactTable .rt-tr .rt-th:first-child {
  width: 15% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:first-child {
  width: 15% !important;
}


.ca-table-billing .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 10% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 10% !important;
}


.ca-table-billing .ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 15% !important;
}

.ca-table-billing .ReactTable .rt-tr .rt-th:nth-of-type(4) {
  width: 50% !important;
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:nth-of-type(4) {
  width: 50% !important;
}

.ca-table-billing .ReactTable .rt-tr .rt-th:last-child {
  width: 10% !important;    
}

.ca-table-billing .ReactTable .rt-tbody .rt-td:last-child {
  width: 10% !important;
  justify-content: flex-start !important;
  white-space: normal;
}



.web-resource-delete-modal .modal-footer {
    display: flex; 
    flex-direction: row;
    justify-content: space-evenly;
}
/* React table */
.ReactTable {
  border: none;
}

.ReactTable .rt-thead {
  min-width: 350px;
}

.ReactTable .rt-thead .rt-th {
  border: none;
  font-weight: bold;
}

.ReactTable .rt-table .rt-tbody {
  border: none;
  min-width: 350px;
}

.ReactTable .rt-tbody .rt-td {
  border: none !important;
}

/* .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0,0,0,0.25);
  height: 6vh;
} */

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:last-child {
  justify-content: flex-end;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  min-height: 45px;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: solid 1px rgba(0,0,0,0.1);
}

.ReactTable .rt-th {
  text-align: start;
}

.ReactTable .rt-tr .rt-th:first-child {
  width: 2vw !important;
}

.ReactTable .rt-tbody .rt-td:first-child {
  width: 2vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 20vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 20vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 20vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 20vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(4) {
  width: 15vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(4) {
  width: 15vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(5) {
  width: 15vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(5) {
  width: 15vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(6) {
  width: 10vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(6) {
  width: 10vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(7) {
  width: 2vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(7) {
  width: 2vw !important;
}

.table-dropdown-button {
  padding: 0;
  background-color: white;
  color: black;
  border: none;
  font-weight: bolder;
}

.table-dropdown-button:hover {
  background: white;
  color: black;
  border: none;
}

.table-dropdown-button:focus {
  outline: none !important;
  box-shadow: none;
}

.table-dropdown-button {
  background-color: white !important;
  color: black !important;
}

.table-dropdown-button::after{
  display: none;
}

.flex-arrange {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table-caret-absolute {
  position: absolute;
}

.table-dropdown-menu {
  overflow: visible !important;
  margin-left: 0.2rem;
  border-radius: 10px;
  background-color: #000000;
  color: #ffffff;
  -webkit-transform: translate(0px, 27px) !important;
          transform: translate(0px, 27px) !important;
  right: 0px !important; 
  left: auto !important;
}

.table-dropdown-menu .dropdown-item {
  padding-left: 1rem;
  color: #ffffff;
}

.table-dropdown-menu .dropdown-item:hover,
.table-dropdown-menu .dropdown-item:active,
.table-dropdown-menu .dropdown-item.active {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}

.ReactTable .-pagination {
  border: 0px solid transparent;
  box-shadow: none;
}

.ReactTable .-pagination .-btn {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: 700;
}

.ReactTable .pagination-bottom button:disabled {
  background-color: #ffffff;
  color: #aaaaaa !important;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  border: 1px solid  rgba(0,0,0,0.1);
  border-radius: 10px;
}

.ReactTable .rt-th:focus {
  outline-width: 0px;
}

.ca-table-smallText {
  font-size: 13px;
  color: #999999;
}

/* .ca-table-link {
  cursor: pointer;
} */

.ca-table-link .btn {
  padding-left: 0px;
}

.ca-table-link .btn-link.disabled,
.ca-table-link .btn-link:disabled {
  color: #999999 !important;
}

.ReactTable .rt-noData {
  top: 40%;
}
.security-page-title {
    font-weight: bolder;
    font-size: x-large;
    padding-bottom: 1.5rem;
}

.security-page-form {
    margin-top: 1.5rem;
}


.security-page-form .form-control {
    margin-bottom: 1.5rem;
    width: 30rem;
}

.security-page-form-label {
    font-weight: bolder;
    padding-left: 0.5rem;
}
.ca-cart-photo-container {
    max-width: 250px;
    margin-right: 15px;
    padding: 0;
}

.ca-cart-photo {
    width: 250px;
}

@media (max-width: 575.98px) {
    .ca-cart-photo-container {
        max-width: 100%;
    }

    .ca-cart-photo {
        width: 100%;
    }    
}

.ca-cart-item {
    border-bottom: 1px solid #eeeeee;
}

.ca-cart-item-details-container {
    padding-left: 0;
    min-width: calc(100% - 265px);
}

.cart-cart-item-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.cart-cart-item-date {
    color: #999999;
    margin-bottom: 15px;
}

.cart-cart-item-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 400;
}

.cart-cart-item-package {
    font-size: 18px;
    font-weight: 700;
}

@media (max-width: 575.98px) {
    .cart-cart-item-title {
        font-size: 16px;
        line-height: 18px;
    }
    
    .cart-cart-item-date {
        margin-bottom: 13px;
    }
    
    .cart-cart-item-details {
        font-size: 13px;
    }
    
    .cart-cart-item-package {
        font-size: 15px;
    }
}

.cart-item-quantity-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.cart-item-quantity {
    display: flex;
    justify-content: flex-end;
}

.cart-item-quantity-label {
    line-height: 28px;
}

.cart-item-quantity input {
    margin-left: 5px;
    padding-left: 10px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    width: 50px;
}

.cart-item-remove {
    display: flex;
    justify-content: flex-end;
    color: #999999;
    font-size: 14px;
}

.cart-item-remove span {
    cursor: pointer !important;
}

.cart-item-choices {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.cart-item-choices-item {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: #999999;
}

.cart-item-price {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: 700;
}

.cart-subtotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}

.card-price {
    font-size: 20px;
    font-weight: 700;
}

.cart-cart-price-details {
    font-size: 15px;
    color: #999999;
    margin-bottom: 30px;
    text-align: justify;
}

.cart-cart-checkout {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.ca-cart-review-item {
  border-bottom: 1px solid #eeeeee;
}

.ca-cart-review-photo-container {
  max-width: 250px;
  margin-right: 15px;
  padding: 0;
}

.ca-cart-review-photo {
  width: 250px;
}

@media (max-width: 575.98px) {
  .ca-cart-review-photo-container {
      max-width: 100%;
  }

  .ca-cart-review-photo {
      width: 100%;
  }    
}

.ca-cart-item-review-details-container {
  padding-left: 0;
  min-width: calc(100% - 265px);
}

.ca-cart-item-review-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.ca-cart-item-review-date {
  color: #999999;
  margin-bottom: 15px;
}

.ca-cart-item-review-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 400;
}

.ca-cart-item-review-package {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .ca-cart-item-review-title {
      font-size: 16px;
      line-height: 18px;
  }
  
  .ca-cart-item-review-date {
      margin-bottom: 13px;
  }
  
  .ca-cart-item-review-details {
      font-size: 13px;
  }
  
  .ca-cart-item-review-package {
      font-size: 15px;
  }
}

.ca-cart-review-quantity {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 15px;
}

.ca-cart-item-review-price {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 700;
}

.cart-review-subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.cart-review-price {
  font-size: 20px;
  font-weight: 700;
}

.cart-review-price-details {
  font-size: 15px;
  color: #999999;
  margin-bottom: 30px;
  text-align: justify;
}

.cart-review-address-title {
  font-size: 16px;
  font-weight: 700;
}

.cart-review-muted-text {
  font-size: 15px;
  color: #999999;
  text-align: justify;
}
.ca-table-geofences .ReactTable .rt-thead {
  min-width: 350px !important;
}

.ca-table-geofences .ReactTable .rt-tbody {
  min-width: 350px !important;
}

.ca-table-geofences .ReactTable .rt-tr .rt-th:first-child {
  width: 35% !important;
}

.ca-table-geofences .ReactTable .rt-tbody .rt-td:first-child {
  width: 35% !important;
  white-space: normal;
}


.ca-table-geofences .ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 50% !important;
}

.ca-table-geofences .ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 50% !important;
}

.ca-table-geofences .ReactTable .rt-tr .rt-th:last-child {
  width: 15% !important;
}

.ca-table-geofences .ReactTable .rt-tbody .rt-td:last-child {
  width: 15% !important;
}
.login-page-align {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.login-page-card {
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
}

.login-page-card-submit-button-section {
    text-align: center;
}

.login-page-card-title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.login-page-logo {
    height: 8rem;
    margin-bottom: 4rem;
}

.ca-link-white {
  color:white !important;
  margin-left:5px;
  margin-right:5px;
}
.login-page-align {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.login-page-card {
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
}

.login-page-card-submit-button-section {
    text-align: center;
}

.login-page-card-title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.login-page-logo {
    height: 8rem;
    margin-bottom: 4rem;
}

.ca-link-white {
  color:white !important;
  margin-left:5px;
  margin-right:5px;
}
.confirm-page-align {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.confirm-page-card {
    width: 100%;
    margin-bottom: 0rem;
    margin-top: 0;
    margin-right: 0;
}

.confirm-page-card-body {
}

.confirm-page-card-form {
}

.confirm-page-card-submit-button-section {
    text-align: center;
}

.confirm-page-card-submit-button {
    border-radius: 4mm;
    height: 2.5rem;
    font-weight: bold;
    font-size: 1rem;
    background-color: black;
    color: white !important;
    border-color: black;
}

.confirm-page-card-submit-button:hover {
    background-color: black;
    border-color: black;
}

.confirm-page-card-submit-button:focus {
    background-color: black;
    border-color: black;
}

.confirm-page-card-title {
    text-align: center;
    font-size: 18px;
}

.confirm-page-logo {
    height: 8rem;
    margin-bottom: 4rem;
}

.confirm-page-card .form-label {
    margin-bottom: 5px;
}
.reset-page-align {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.reset-page-card {
    width: 100%;
    margin-bottom: 0rem;
    margin-top: 0;
    margin-right: 0;
}

.reset-page-card-body {
}

.reset-page-card-form {
}

.reset-page-card-submit-button-section {
    text-align: center;
}

.reset-page-card-submit-button {
    border-radius: 4mm;
    height: 2.5rem;
    font-weight: bold;
    font-size: 1rem;
    background-color: black;
    color: white !important;
    border-color: black;
}

.reset-page-card-submit-button:hover {
    background-color: black;
    border-color: black;
}

.reset-page-card-submit-button:focus {
    background-color: black;
    border-color: black;
}

.reset-page-card-title {
    text-align: center;
    font-size: 18px;
}

.reset-page-logo {
    height: 8rem;
    margin-bottom: 4rem;
}

.reset-page-card .form-label {
    margin-bottom: 5px;
}
