/* React table */
.ReactTable {
  border: none;
}

.ReactTable .rt-thead {
  min-width: 350px;
}

.ReactTable .rt-thead .rt-th {
  border: none;
  font-weight: bold;
}

.ReactTable .rt-table .rt-tbody {
  border: none;
  min-width: 350px;
}

.ReactTable .rt-tbody .rt-td {
  border: none !important;
}

/* .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0,0,0,0.25);
  height: 6vh;
} */

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:last-child {
  justify-content: flex-end;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  min-height: 45px;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: solid 1px rgba(0,0,0,0.1);
}

.ReactTable .rt-th {
  text-align: start;
}

.ReactTable .rt-tr .rt-th:first-child {
  width: 2vw !important;
}

.ReactTable .rt-tbody .rt-td:first-child {
  width: 2vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(2) {
  width: 20vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(2) {
  width: 20vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(3) {
  width: 20vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(3) {
  width: 20vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(4) {
  width: 15vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(4) {
  width: 15vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(5) {
  width: 15vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(5) {
  width: 15vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(6) {
  width: 10vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(6) {
  width: 10vw !important;
}

.ReactTable .rt-tr .rt-th:nth-of-type(7) {
  width: 2vw !important;
}

.ReactTable .rt-tbody .rt-td:nth-of-type(7) {
  width: 2vw !important;
}

.table-dropdown-button {
  padding: 0;
  background-color: white;
  color: black;
  border: none;
  font-weight: bolder;
}

.table-dropdown-button:hover {
  background: white;
  color: black;
  border: none;
}

.table-dropdown-button:focus {
  outline: none !important;
  box-shadow: none;
}

.table-dropdown-button {
  background-color: white !important;
  color: black !important;
}

.table-dropdown-button::after{
  display: none;
}

.flex-arrange {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table-caret-absolute {
  position: absolute;
}

.table-dropdown-menu {
  overflow: visible !important;
  margin-left: 0.2rem;
  border-radius: 10px;
  background-color: #000000;
  color: #ffffff;
  transform: translate(0px, 27px) !important;
  right: 0px !important; 
  left: auto !important;
}

.table-dropdown-menu .dropdown-item {
  padding-left: 1rem;
  color: #ffffff;
}

.table-dropdown-menu .dropdown-item:hover,
.table-dropdown-menu .dropdown-item:active,
.table-dropdown-menu .dropdown-item.active {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}

.ReactTable .-pagination {
  border: 0px solid transparent;
  box-shadow: none;
}

.ReactTable .-pagination .-btn {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: 700;
}

.ReactTable .pagination-bottom button:disabled {
  background-color: #ffffff;
  color: #aaaaaa !important;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  border: 1px solid  rgba(0,0,0,0.1);
  border-radius: 10px;
}

.ReactTable .rt-th:focus {
  outline-width: 0px;
}

.ca-table-smallText {
  font-size: 13px;
  color: #999999;
}

/* .ca-table-link {
  cursor: pointer;
} */

.ca-table-link .btn {
  padding-left: 0px;
}

.ca-table-link .btn-link.disabled,
.ca-table-link .btn-link:disabled {
  color: #999999 !important;
}

.ReactTable .rt-noData {
  top: 40%;
}