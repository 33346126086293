.ca-artwork-buy-modal .modal-title {
    font-size: 20px;
}

.ca-artwork-buy-modal .modal-body .modal-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.ca-artwork-buy-modal .modal-body .ca-modal-title-detail {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
}

.ca-artwork-buy-modal .modal-footer {
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
}

.ca-buy-modal-price {
    margin-bottom: 20px;
    font-size: 16px;
}

.ca-buy-modal-price .ca-price-detail {
    font-size: 16px;
    color: #999999;
}

.ca-buy-modal-pkg {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}

.ca-buy-modal-print {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}