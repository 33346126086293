.no-margin {
    display: flex;
    flex-direction: row;
    margin: 0;
}

.no-padding {
    padding: 0;
}

@media (min-width: 1200px){
    .no-padding{
        max-width: 100%;
    }
}

@media (min-width: 1312px){
    .no-padding{
        max-width: 80%;
    }
}

@media (min-width: 1482px){
    .no-padding{
        max-width: 70%;
    }
}


.m-right10{
    margin-right: 2rem;
}

.add-users-button {
    border-radius: 4mm;
    font-weight: bolder;
    font-size: large;
    background-color: black;
    color: white;
    border-color: black;
}

.add-users-button:hover {
    color: white;
    background-color: black;
    border-color: black;
}

.input.users-search.form-control {
    padding: 0;
}

/* .users-search {
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    border: 1px solid #cccccc;
    border-radius: 10px;
} */

.dropdown-toggle::after{
    position: relative;
}

table {
    border-spacing: 0;
    border: 1px solid black;
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

tr {
    height: 3rem;
}

th,
td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid lightgrey;
    border-right: 0;
}

td:last-child {
    border-right: 0;
}

.pagination {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

.marginright-1rem {
    margin-right: 1rem;
}

.pagination-button {
    border-radius: 20%;
    text-align: center;
}

.arrange-page-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page-input {
    width: 4rem;
}

.table-column-id {
    width: 5rem;
}

.table-column-name {
    width: 30rem;
}

.table-column-username {
    width: 30rem;
}

.table-column-type {
    width: 15rem;
}

.table-column-membership {
    width: 15rem;
}

.table-column-status {
    width: 10rem;
}

.table-column-caret {
    width: auto;
    color: black;
    font-size: 1.5rem;
    padding: 0;
    font-weight: bolder;
    text-align: right;
    margin-right: 1rem;
}

.sort-caret {
    font-size: 1rem;
    margin-left: 0.5rem;
}

/* .users-status-dropdown {
    margin-right: 2rem;
} */

