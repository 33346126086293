.ca-cart-photo-container {
    max-width: 250px;
    margin-right: 15px;
    padding: 0;
}

.ca-cart-photo {
    width: 250px;
}

@media (max-width: 575.98px) {
    .ca-cart-photo-container {
        max-width: 100%;
    }

    .ca-cart-photo {
        width: 100%;
    }    
}

.ca-cart-item {
    border-bottom: 1px solid #eeeeee;
}

.ca-cart-item-details-container {
    padding-left: 0;
    min-width: calc(100% - 265px);
}

.cart-cart-item-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.cart-cart-item-date {
    color: #999999;
    margin-bottom: 15px;
}

.cart-cart-item-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 400;
}

.cart-cart-item-package {
    font-size: 18px;
    font-weight: 700;
}

@media (max-width: 575.98px) {
    .cart-cart-item-title {
        font-size: 16px;
        line-height: 18px;
    }
    
    .cart-cart-item-date {
        margin-bottom: 13px;
    }
    
    .cart-cart-item-details {
        font-size: 13px;
    }
    
    .cart-cart-item-package {
        font-size: 15px;
    }
}

.cart-item-quantity-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.cart-item-quantity {
    display: flex;
    justify-content: flex-end;
}

.cart-item-quantity-label {
    line-height: 28px;
}

.cart-item-quantity input {
    margin-left: 5px;
    padding-left: 10px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    width: 50px;
}

.cart-item-remove {
    display: flex;
    justify-content: flex-end;
    color: #999999;
    font-size: 14px;
}

.cart-item-remove span {
    cursor: pointer !important;
}

.cart-item-choices {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.cart-item-choices-item {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: #999999;
}

.cart-item-price {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: 700;
}

.cart-subtotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}

.card-price {
    font-size: 20px;
    font-weight: 700;
}

.cart-cart-price-details {
    font-size: 15px;
    color: #999999;
    margin-bottom: 30px;
    text-align: justify;
}

.cart-cart-checkout {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}
