.App {
  text-align: center;
}

body {
  font-size: 15px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.main-container {
  padding: 0 3.5rem;
}

@media (max-width: 992px) {

  .main-container {
    padding: 0 2.5rem;
  }

}

.routing-link,.routing-link:hover,.routing-link:active {
  text-decoration: none;
  color: white;
}
