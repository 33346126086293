.ca-table-company-artist .ReactTable .rt-thead {
  min-width: 718px !important;
}

.ca-table-company-artist .ReactTable .rt-tbody {
  min-width: 718px !important;
}

.ca-table-company-artist .ReactTable .rt-tr .rt-th:first-child {
  width: 55% !important;
}

.ca-table-company-artist .ReactTable .rt-tbody .rt-td:first-child {
  width: 55% !important;
}

.ca-table-company-artist .ReactTable .rt-tr .rt-th:last-child {
  width: 20% !important;    
}

.ca-table-company-artist .ReactTable .rt-tbody .rt-td:last-child {
  width: 20% !important;
  justify-content: flex-start !important;
  /* white-space: normal; */
}