.confirm-page-align {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.confirm-page-card {
    width: 100%;
    margin-bottom: 0rem;
    margin-top: 0;
    margin-right: 0;
}

.confirm-page-card-body {
}

.confirm-page-card-form {
}

.confirm-page-card-submit-button-section {
    text-align: center;
}

.confirm-page-card-submit-button {
    border-radius: 4mm;
    height: 2.5rem;
    font-weight: bold;
    font-size: 1rem;
    background-color: black;
    color: white !important;
    border-color: black;
}

.confirm-page-card-submit-button:hover {
    background-color: black;
    border-color: black;
}

.confirm-page-card-submit-button:focus {
    background-color: black;
    border-color: black;
}

.confirm-page-card-title {
    text-align: center;
    font-size: 18px;
}

.confirm-page-logo {
    height: 8rem;
    margin-bottom: 4rem;
}

.confirm-page-card .form-label {
    margin-bottom: 5px;
}